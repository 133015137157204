import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import $ from "jquery";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import { Match } from "../types/match.ts";
import "./matchcomponentsimple.css";

class MatchComponentSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Padding: false,
    };
  }

  Greeting(props) {
    const Minuate = props.toggleSpacer;
    if (Minuate >= 800) {
      return <div>SPACER</div>;
    }
    return "";
  }

  componentDidMount() {
    var $this = $("div.row.events div.events");
    console.log($this);
    $this.scrollLeft(385);
  }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  handlePlayerCLick = (PlayerId) => {
    console.log(PlayerId);
    this.props.history.push(`/player/${PlayerId}`);
    // history.push("/home");
  };

  render() {
    // const values = Object.entries(MatchData).map((match, index) => {
    //   return match;
    // });

    //console.log(MatchData.HomeTeam);

    var match = this.props.MatchData;

    var HomeLogo = "http://fodboldstatistik.com/teamx.png";
    var VisitorLogo = "http://fodboldstatistik.com/teamx.png";
    if (match.HomeTeam !== undefined && match.VisitorTeam !== undefined) {
      if (match.HomeTeam.LogoFileName !== "")
        HomeLogo = "/teams/" + match.HomeTeam.LogoFileName;
      if (match.VisitorTeam.LogoFileName !== "") {
        VisitorLogo = "/teams/" + match.VisitorTeam.LogoFileName;
      }
    }

    return (
      <div className="row match" onClick={() => this.handleClick(match.Id)}>
        {/* <Link to={`/match/${match.Id}`}> */}
        <div className="row">
          <div className="col-5 icon home">
            <img src={HomeLogo} />
            <span className="team">{match.HomeTeam.Name}</span>
            {/* <div className="col-3 goals"> */}

            {/* </div> */}
          </div>
          <div className="col-2 score home">
            {match.HomeGoals} - {match.VisitorGoals}
          </div>
          <div className="col-5 icon visitor">
            <span className="team">{match.VisitorTeam.Name}</span>
            <img src={VisitorLogo} />
            {/* <div className="col-3 goals"> */}

            {/* </div> */}
          </div>
          {/* </Link> */}
        </div>
        <div class="row matchdate">
          <Moment style={{ whiteSpace: "pre" }} format={"DD MMM YYYY"}>
            {match.Date}
          </Moment>
        </div>
        <div className="row goals">
          <div className="col-6 home">
            {match.Events.map((event, index) => {
              var MinuteOvertime = "";
              if (event.MinuteOvertime !== 0)
                MinuteOvertime = "+" + event.MinuteOvertime;

              if (
                event.MatchEventType == "Goal" ||
                event.MatchEventType == "Own goal"
              ) {
                var PlayerName = "";
                var EventMinute = event.Minute;
                var Comment = "";
                if (event.Comment.length > 0) Comment = event.Comment;
                if (Comment.toLowerCase() == "str")
                  Comment = " (" + Comment.toUpperCase() + ")";

                if (event.TeamId == match.HomeTeam.Id) {
                  PlayerName =
                    event.PlayerFirstName +
                    Comment +
                    " (" +
                    EventMinute +
                    "<div class='overtime'>" +
                    MinuteOvertime +
                    "</div>)";

                  if (event.MatchEventType == "Own goal") {
                    PlayerName += " SELVMÅL";
                  }
                } else {
                }

                return (
                  <div
                    className="goal"
                    dangerouslySetInnerHTML={{
                      __html: PlayerName,
                    }}
                  ></div>
                );
              }
            })}
          </div>
          <div className="col-6 visitor">
            {match.Events.map((event, index) => {
              if (
                event.MatchEventType == "Goal" ||
                event.MatchEventType == "Own goal"
              ) {
                var MinuteOvertime = "";
                if (event.MinuteOvertime !== 0)
                  MinuteOvertime = "+" + event.MinuteOvertime;
                var PlayerName = "";
                var EventMinute = event.Minute;
                var Comment = "";
                if (event.Comment.length > 0) Comment = event.Comment;
                if (Comment.toLowerCase() == "str")
                  Comment = " (" + Comment.toUpperCase() + ")";

                if (event.TeamId == match.HomeTeam.Id) {
                } else {
                  PlayerName =
                    event.PlayerFirstName +
                    Comment +
                    " (" +
                    EventMinute +
                    "<div class='overtime'>" +
                    MinuteOvertime +
                    "</div>)";

                  if (event.MatchEventType == "Own goal") {
                    PlayerName += " SELVMÅL";
                  }
                }

                return (
                  <div
                    className="goal"
                    dangerouslySetInnerHTML={{
                      __html: PlayerName,
                    }}
                  ></div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MatchComponentSimple;
