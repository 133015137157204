import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import moment from "moment";
import "./playeroftheyearcomponent.css";

const players = [
  {
    firstName: 'George',
    lastName: 'Lees',
    playerId: 410,
    year: '1958',
  },
  {
    firstName: 'Egon',
    lastName: 'Henriksen',
    playerId: 413,
    year: '1959',

  },
  {
    firstName: 'Ib',
    lastName: 'Eskildsen',
    playerId: 387,
    year: '1960',
  },
  {
    firstName: 'Søren',
    lastName: 'Andersen',
    playerId: 389,
    year: '1960',
  },
  {
    firstName: 'Birger ',
    lastName: 'Larsen',
    playerId: 365,
    year: '1961',
  },
  {
    firstName: 'Kaj ',
    lastName: 'Hansen',
    playerId: 366,
    year: '1962',
  },
  {
    firstName: 'Finn Willy',
    lastName: 'Sørensen',
    playerId: 364,
    year: '1963',
  },
  {
    firstName: 'Birger ',
    lastName: 'Larsen',
    playerId: 365,
    year: '1964',
  },
  {
    firstName: 'Leif B. ',
    lastName: 'Nielsen',
    playerId: 399,
    year: '1965',
  },
  {
    firstName: 'Leif B. ',
    lastName: 'Nielsen',
    playerId: 399,
    year: '1966',
  },
  {
    firstName: 'Hardy ',
    lastName: 'Hansen',
    playerId: 383,
    year: '1967',
  },
  {
    firstName: 'Birger ',
    lastName: 'Larsen',
    playerId: 365,
    year: '1968',
  },
  {
    firstName: 'Jørn ',
    lastName: 'Jeppesen',
    playerId: 359,
    year: '1969',
  },
  {
    firstName: 'Bent ',
    lastName: 'Hougaard',
    playerId: 466,
    year: '1970',
  },
  {
    firstName: 'Dennis ',
    lastName: 'Nielsen',
    playerId: 363,
    year: '1971',
  },
  {
    firstName: 'Flemming ',
    lastName: 'Ahlberg',
    playerId: 378,
    year: '1972',
  },
  {
    firstName: 'Lars ',
    lastName: 'Larsen',
    playerId: 206,
    year: '1973',
  },
  {
    firstName: 'Flemming ',
    lastName: 'Mortensen',
    playerId: 360,
    year: '1974',
  },
  {
    firstName: 'Jan ',
    lastName: 'Poulsen',
    playerId: 334,
    year: '1975',
  },
  {
    firstName: 'Bjarne ',
    lastName: 'Andersen',
    playerId: 446,
    year: '1976',
  },
  {
    firstName: 'Ole ',
    lastName: 'Mørch',
    playerId: 380,
    year: '1977',
  },
  {
    firstName: 'Per ',
    lastName: 'Wind',
    playerId: 204,
    year: '1978',
  },
  {
    firstName: 'Lars ',
    lastName: 'Larsen',
    playerId: 206,
    year: '1979',
  },
  {
    firstName: 'Henrik ',
    lastName: 'Thomsen',
    playerId: 259,
    year: '1980',
  },
  {
    firstName: 'Lars ',
    lastName: 'Larsen',
    playerId: 206,
    year: '1981',
  },
  {
    firstName: 'John Finn',
    lastName: 'Hansen',
    playerId: 345,
    year: '1982',
  },
  {
    firstName: 'Ole ',
    lastName: 'Rasmussen',
    playerId: 261,
    year: '1983',
  },
  {
    firstName: 'Henrik ',
    lastName: 'Thomsen',
    playerId: 259,
    year: '1984',
  },
  {
    firstName: 'Henrik ',
    lastName: 'Thomsen',
    playerId: 259,
    year: '1985',
  },
  {
    firstName: 'Kim ',
    lastName: 'Mikkelsen',
    playerId: 221,
    year: '1986',
  },
  {
    firstName: 'Michael ',
    lastName: 'Lyng',
    playerId: 264,
    year: '1987',
  },
  {
    firstName: 'Lars ',
    lastName: 'Broustbo',
    playerId: 258,
    year: '1988',
  },
  {
    firstName: 'Frank ',
    lastName: 'Johansen',
    playerId: 295,
    year: '1989',
  },
  {
    firstName: 'Allan ',
    lastName: 'Jensen',
    playerId: 202,
    year: '1990',
  },
  {
    firstName: 'Tony ',
    lastName: 'Carlsen',
    playerId: 228,
    year: '1991',
  },
  {
    firstName: 'Dan ',
    lastName: 'Eggen',
    playerId: 207,
    year: '1992',
  },
  {
    firstName: 'Lars ',
    lastName: 'Broustbo',
    playerId: 258,
    year: '1993',
  },
  {
    firstName: 'Lars ',
    lastName: 'Broustbo',
    playerId: 258,
    year: '1994',
  },
  {
    firstName: 'Allan ',
    lastName: 'Jensen',
    playerId: 202,
    year: '1995',
  },
  {
    firstName: 'Claus ',
    lastName: 'Jørgensen',
    playerId: 185,
    year: '1996',
  },
  {
    firstName: 'Allan ',
    lastName: 'Jensen',
    playerId: 202,
    year: '1997',
  },
  {
    firstName: 'Michael ',
    lastName: 'Giolbas',
    playerId: 163,
    year: '1997-98',
  },
  {
    firstName: 'Michael ',
    lastName: 'Giolbas',
    playerId: 163,
    year: '1998-99',
  },
  {
    firstName: 'Martin ',
    lastName: 'Jeppesen',
    playerId: 96,
    year: '1999-00',
  },
  {
    firstName: 'Ketil ',
    lastName: 'Clorius',
    playerId: 788,
    year: '2000-01',
  },
  {
    firstName: 'Mirko  ',
    lastName: 'Selak',
    playerId: 794,
    year: '2001-02',
  },
  {
    firstName: 'Søren ',
    lastName: 'Krogh',
    playerId: 172,
    year: '2002-03',
  },
  {
    firstName: 'Lars ',
    lastName: 'Brøgger',
    playerId: 498,
    year: '2003-04',
  },
  {
    firstName: 'Jón Rói ',
    lastName: 'Jacobsen',
    playerId: 776,
    year: '2005',
  },
  {
    firstName: 'Wassim El',
    lastName: 'Banna',
    playerId: 500,
    year: '2006',
  },
  {
    firstName: 'Morten ',
    lastName: 'Hamm',
    playerId: 533,
    year: '2007',
  },
  {
    firstName: 'Atli ',
    lastName: 'Danielsen',
    playerId: 318,
    year: '2008',
  },
  {
    firstName: 'Tim ',
    lastName: 'Ilsø',
    playerId: 91,
    year: '2009',
  },
  {
    firstName: 'Mikkel ',
    lastName: 'Andersson',
    playerId: 53,
    year: '2010-11',
  },
  {
    firstName: 'Jakob Køhler',
    lastName: 'Hansen',
    playerId: 86,
    year: '2011-12',
  },
  {
    firstName: 'Thomas ',
    lastName: 'Krause',
    playerId: 92,
    year: '2012-13',
  },
  {
    firstName: 'Christian ',
    lastName: 'Stokholm',
    playerId: 67,
    year: '2013-14',
  },
  {
    firstName: 'Jeppe ',
    lastName: 'Østenkær',
    playerId: 124,
    year: '2014-15',
  },
  {
    firstName: 'Andreas Theil',
    lastName: 'Lundberg',
    playerId: 61,
    year: '2015-16',
  },
  {
    firstName: 'Rasmus Louie',
    lastName: 'Larsen',
    playerId: 75,
    year: '2016-17',
  },
  {
    firstName: 'Marco ',
    lastName: 'Brylov',
    playerId: 35,
    year: '2017-18',
  },
  {
    firstName: 'Marco ',
    lastName: 'Brylov',
    playerId: 35,
    year: '2018-19',
  },
  {
    firstName: 'Tonny ',
    lastName: 'Adamsen',
    playerId: 47,
    year: '2019 Efterår',
  },
  {
    firstName: 'IKKE TILDELT ',
    lastName: '',
    playerId: 0,
    year: '2019-20',
  },
  {
    firstName: 'IKKE TILDELT, MÅSKE P.G.A. CORONA NEDLUKNINGEN',
    lastName: '',
    playerId: 0,
    year: '2020-21',
  },
  {
    firstName: 'Frederik  V.',
    lastName: 'Kristensen',
    playerId: 849,
    year: '2021-22',
  },
  {
    firstName: 'Salieu',
    lastName: 'Drammeh',
    playerId: 870,
    year: '2022-23',
  },
  {
    firstName: 'Gustav O.',
    lastName: 'Hansen',
    playerId: 884,
    year: '2023-24',
  },
];

class PlayerOfTheYearComponent extends Component {
  constructor() {
    super();
    this.state = {
      players: players,
    };

  }

  handlePlayerClick = (PlayerId) => {
    console.log(PlayerId);
    this.props.history.push(`/player/${PlayerId}`);
    // history.push("/home");
  };

  componentDidMount() {
    // this.setState({ PlayersData: null });

    // this.loadPlayers();

    //this.props.history.push(`/hest`);
  }

  render() {
    return (
      <div className="container playeroftheyear">
        <div className="statslead">Boldklubben FREM - Årets spiller</div>

        <div className="player" style={{ marginTop: '20px' }}>
          <div className="left" style={{ fontWeight: 'bold' }}>
            År
          </div>
          <div className="right" style={{ fontWeight: 'bold' }}>
            Navn
          </div>

        </div>

        {players !== null && (
          players.map((player) => {
            return (
              <div className="player">
                <div className="left">
                  <div className="name">{player.year}</div>
                </div>
                <div className="right" style={{ cursor: 'pointer' }} onClick={() => this.handlePlayerClick(player.playerId)}>
                  <div className="name">{player.firstName} {player.lastName}</div>
                </div>
              </div>
            )
          })
        )}

        <br />
        <div className="container">
          <div className="copyright">
            &#169; Statistikoplysningerne er indsamlet og indkodet af BK FREMs
            officielle statistikfører: Steen Meisner Bjerre, 23 61 92 72,
            steenbjerre@hotmail.com til hvem henvises, såfremt der er
            ændringer/tilføjelser e.a. til spillerprofilen.
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default PlayerOfTheYearComponent;
