import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import Select from "react-select";
import MatchItem from "./matchitem";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import queryString from "query-string";
import "./teamslistcomponent.css";

class TeamsList extends Component {
  constructor() {
    super();
    this.props = {
      history: {},
    };

    this.state = {
      TeamsData: [],
    };
  }

  componentDidMount() {
    this.setState({ TeamsData: null });

    this.reloadTeams();

    //this.props.history.push(`/hest`);
  }

  reloadTeams() {
    fetch("http://soccerstats.guideme.today/api/team")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ TeamsData: data });
      })
      .catch(console.log);
  }

  handleClick = (TeamId) => {
    console.log(TeamId);
    this.props.history.push(`/team/${TeamId}`);
    // history.push("/home");
  };

  render() {
    var TeamsData = this.state.TeamsData != null ? this.state.TeamsData : [];

    return (
      <div className="container teams">
        {TeamsData.map((team) => {
          var TeamLogo = "http://fodboldstatistik.com/teamx.png";
          // var VisitorLogo = "http://fodboldstatistik.com/teamx.png";
          if (team.LogoFileName !== "") {
            TeamLogo = "/teams/" + team.LogoFileName;
          }
          //   if (match.HomeTeam.LogoFileName !== "")
          //     HomeLogo = "/teams/" + match.HomeTeam.LogoFileName;
          //   if (match.VisitorTeam.LogoFileName !== "") {
          //     VisitorLogo = "/teams/" + match.VisitorTeam.LogoFileName;
          //   }
          // }

          return (
            <div className="row team" onClick={() => this.handleClick(team.Id)}>
              {/* <Link to={`/match/${match.Id}`}> */}
              <div className="row">
                <div className="col-6 icon home">
                  <img src={TeamLogo} />
                  <span>{team.Name}</span>
                </div>
                <div className="col-6 text-right">
                  {team.MatchesTotal} <i>kampe</i>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TeamsList;
