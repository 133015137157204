import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import $ from "jquery";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import { Match } from "../types/match.ts";
import "./latestmatchcomponent.css";

class LatestMatchComponent extends Component {
  constructor() {
    super();
    this.state = {
      MatchData: {},
      HomeTeam: {},
      VisitorTeam: {},
      HomeTeamPlayers: [],
      Events: [],
      Padding: false,
    };
  }

  Greeting(props) {
    const Minuate = props.toggleSpacer;
    if (Minuate >= 800) {
      return <div>SPACER</div>;
    }
    return "";
  }

  componentDidMount() {
    fetch("http://soccerstats.guideme.today/api/Match/latest")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));
        console.log(data.HomeTeam.Name);
        this.setState({
          MatchData: data,
          HomeTeam: data.HomeTeam,
          VisitorTeam: data.VisitorTeam,
          HomeTeamPlayers: data.HomeTeamPlayers,
          Events: data.Events
        });

        var $this = $("div.row.events div.events");
        console.log($this);
        $this.scrollLeft(385);
      })
      .catch(console.log);
  }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  render() {
    // const values = Object.entries(this.state.MatchData).map((match, index) => {
    //   return match;
    // });

    //console.log(this.state.MatchData.HomeTeam);

    var HomeLogo = "http://fodboldstatistik.com/teamx.png";
    var VisitorLogo = "http://fodboldstatistik.com/teamx.png";
    if (this.state.HomeTeam.Name !== "")
      //HomeLogo = "http://fodboldstatistik.com/frem.png";
      HomeLogo = "/teams/" + this.state.HomeTeam.LogoFileName;
    if (this.state.VisitorTeam.LogoFileName !== "") {
      VisitorLogo = "/teams/" + this.state.VisitorTeam.LogoFileName;
    }

    var Startes = this.state.HomeTeamPlayers.filter(function (player) {
      return player.RowInMatch !== "99";
    });
    var Substitutes = this.state.HomeTeamPlayers.filter(function (player) {
      return player.RowInMatch === "99";
    });

    var AdminLink =
      '<a href="http://fodboldstatistik.com/admin/match.html?matchid=' +
      this.state.MatchData.Id +
      '" style="color:transparent;">EDIT MATCH</a>';

    return (
      <div
        className="container latestmatch"
        onClick={() => this.handleClick(this.state.MatchData.Id)}
      >
        <div className="row match">
          <div className="col-3 icon home">
            <img src={HomeLogo} />
          </div>
          <div className="col-1 goals home">
            {this.state.MatchData.HomeGoals}
          </div>
          <div className="col-2 seperator">-</div>
          <div className="col-1 goals visitor">
            {this.state.MatchData.VisitorGoals}
          </div>
          <div className="col-3 icon visitor">
            <img src={VisitorLogo} />
          </div>
        </div>
        <div className="row teams">
          <div className="col-5 home">{this.state.HomeTeam.Name}</div>
          <div className="col-5 visitor">{this.state.VisitorTeam.Name}</div>
        </div>
      </div>
    );
  }
}

export default LatestMatchComponent;
