import React, { Component } from "react";
import "./playercomponent.css";
import Moment from "react-moment";
import "moment-timezone";
import $ from "jquery";
import MatchListComponent from "./matcheslist";
import MatchComponentDetails from "./matchcomponentdetails";
import MatchComponentSimple from "./matchcomponentsimple";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules

class PlayerComponent extends Component {
  constructor() {
    super();
    this.state = {
      PlayerData: {},
      MatchesData: [],
      FilteredMatchesData: [],
      ShowExpanded: false,
      matchFilterText: ''
    };
  }

  reloadMatches(playerId) {
    fetch("http://soccerstats.guideme.today/api/Match/player/" + playerId)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          MatchesData: data,
          FilteredMatchesData: data,
        });
      })
      .catch(console.log);
  }

  componentDidMount() {
    let playerId = this.props.location.pathname.split("/")[2];
    console.log("Id: ", playerId, this.props);
    fetch("http://soccerstats.guideme.today/api/player/" + playerId)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));
        this.setState({ PlayerData: data });

        var $this = $("div.row.events div.events");
        console.log($this);
        $this.scrollLeft(385);
      })
      .catch(console.log);

    this.reloadMatches(playerId);
  }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  handleExpandedChange = (event) => {
    console.log("handleExpandedChange");
    console.log(event.target.checked);

    this.setState({ ShowExpanded: event.target.checked });
  };

  toggleMatches() {
    console.log("Toggle matches");
    $(".playersmatches").slideToggle();
    if ($(".toggleLead").text() == "Vis") {
      $(".toggleLead").text("Skjul");
    } else {
      $(".toggleLead").text("Vis");
      $("div.row.events div.events").each(function (index, item) {
        $(item).scrollLeft(385);
      });
    }
  }

  handleMatchFilter(filterType) {
    var filteretMatches = [];
    var matchFilterText = '';

    if (filterType === 'all') {
      filteretMatches = this.state.MatchesData;
      matchFilterText = '';
    }

    if (filterType === 'yellow') {
      filteretMatches = this.state.MatchesData.filter((match) => match.Events.some((event) => event.MatchEventType === "Yellow" && event.PlayerId === this.state.PlayerData.Id))
      matchFilterText = 'viser kun kampe med gult kort';
    }

    if (filterType === 'red') {
      filteretMatches = this.state.MatchesData.filter((match) => match.Events.some((event) => (event.MatchEventType === "Red" || event.MatchEventType === "RedYellow") && event.PlayerId === this.state.PlayerData.Id))
      matchFilterText = 'viser kun kampe med rødt kort';
    }

    if (filterType === 'replacementOut') {
      filteretMatches = this.state.MatchesData.filter((match) => match.Events.some((event) => event.MatchEventType === "Replacement" && event.ReplacementPlayerId === this.state.PlayerData.Id))
      matchFilterText = 'viser kun kampe med udskiftning';
    }

    if (filterType === 'replacementIn') {
      filteretMatches = this.state.MatchesData.filter((match) => match.Events.some((event) => event.MatchEventType === "Replacement" && event.PlayerId === this.state.PlayerData.Id))
      matchFilterText = 'viser kun kampe med indskiftning';
    }

    if (filterType === 'goal') {
      filteretMatches = this.state.MatchesData.filter((match) => match.Events.some((event) => event.MatchEventType === "Goal" && event.PlayerId === this.state.PlayerData.Id))
      matchFilterText = 'viser kun kampe med mål';
    }

    if (filterType === 'won') {
      filteretMatches = this.state.MatchesData.filter((match) => match.VisitorTeam.Id === 100 && match.VisitorGoals > match.HomeGoals || match.HomeTeam.Id === 100 && match.VisitorGoals < match.HomeGoals)
      matchFilterText = 'viser kun vundne kampe';
    }

    if (filterType === 'lost') {
      filteretMatches = this.state.MatchesData.filter((match) => match.VisitorTeam.Id === 100 && match.VisitorGoals < match.HomeGoals || match.HomeTeam.Id === 100 && match.VisitorGoals > match.HomeGoals)
      matchFilterText = 'viser kun tabte kampe';
    }

    if (filterType === 'tie') {
      filteretMatches = this.state.MatchesData.filter((match) => match.VisitorGoals === match.HomeGoals)
      matchFilterText = 'viser kun uafgjorte kampe';
    }

    console.log(filteretMatches);
    this.setState({
      FilteredMatchesData: filteretMatches,
      matchFilterText: matchFilterText
    });
  }

  render() {
    const pic =
      "http://fodboldstatistik.com/profile/" +
      this.state.PlayerData.ProfilePictureFileName;

    var AdminLink =
      '<a href="http://fodboldstatistik.com/admin/player.html?playerid=' +
      this.state.PlayerData.Id +
      '" style="color:transparent;">EDIT PLAYER</a>';

    var NationalMatches = "";
    if (this.state.PlayerData.NationalMatches !== "") {
      NationalMatches = this.state.PlayerData.NationalMatches;
    }

    return (
      <div>
        <div class="container player">
          <div className="row">
            <div className="col-12 playername">
              {this.state.PlayerData.FirstName} {this.state.PlayerData.LastName}
            </div>
          </div>
          <div className="row bio">
            <div className="col-5 profilepic">
              <img src={pic} />
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-7 lead">POSITION</div>
              </div>
              <div className="row">
                <div className="col-7 info">
                  {this.state.PlayerData.Position}
                </div>
              </div>
              <div className="row">
                <div className="col-7 lead">Højde</div>
              </div>
              <div className="row">
                <div className="col-7 info">{this.state.PlayerData.Height}</div>
              </div>
              <div className="row">
                <div className="col-7 lead">Vægt</div>
              </div>
              <div className="row">
                <div className="col-7 info">{this.state.PlayerData.Weight}</div>
              </div>
              <div className="row">
                <div className="col-7 lead">Født</div>
              </div>
              <div className="row">
                <div className="col-7 info  ">
                  <Moment
                    style={{ whiteSpace: "pre" }}
                    format={"DD[.] MMM YYYY"}
                  >
                    {this.state.PlayerData.BirthDate}
                  </Moment>
                </div>
              </div>
            </div>
          </div>
          <div className="row gamestats">
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">KAMPE</div>
              </div>
              <div className="row">
                <div className="col-12 value center" style={{ cursor: 'pointer' }} onClick={() => this.handleMatchFilter('all')}>
                  {this.state.PlayerData.MatchesTotal}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">VUND.</div>
              </div>
              <div className="row">
                <div className="col-12 value center" style={{ cursor: 'pointer' }} onClick={() => this.handleMatchFilter('won')}>
                  {this.state.PlayerData.MatchesWon}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">UAFG.</div>
              </div>
              <div className="row">
                <div className="col-12 value center" style={{ cursor: 'pointer' }} onClick={() => this.handleMatchFilter('tie')}>
                  {this.state.PlayerData.MatchesTie}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">TABTE</div>
              </div>
              <div className="row">
                <div className="col-12 value center" style={{ cursor: 'pointer' }} onClick={() => this.handleMatchFilter('lost')}>
                  {this.state.PlayerData.MatchesLost}
                </div>
              </div>
            </div>
            <div className="col-2">&nbsp;</div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">MÅL</div>
              </div>
              <div className="row">
                <div className="col-12 value center" style={{ cursor: 'pointer' }} onClick={() => this.handleMatchFilter('goal')}>
                  {this.state.PlayerData.GoalsTotal}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 lead">Debut</div>
          </div>
          <div className="row">
            <div
              className="col-12 info"
              dangerouslySetInnerHTML={{
                __html: this.state.PlayerData.Debut,
              }}
            ></div>
          </div>
          {(this.state.PlayerData.YellowTotal > 0 || this.state.PlayerData.RedTotal > 0 || this.state.PlayerData.RedYellowTotal > 0 || this.state.PlayerData.ReplacementOutTotal > 0 || this.state.PlayerData.ReplacementInTotal > 0) && (
            <>
              <div className="row">
                <div className="col-12 lead">Kort & udskiftninger</div>
              </div>
              <div className="row">
                <div className="col-12 info" style={{ width: '100px' }}>
                  <div style={{ display: 'block', cursor: 'pointer' }} title="Gule kort" onClick={() => this.handleMatchFilter('yellow')}>
                    <img src="http://fodboldstatistik.com/yellow.png" /> {this.state.PlayerData.YellowTotal} gule kort
                  </div>
                  <div style={{ display: 'block', cursor: 'pointer' }} title="Røde kort" onClick={() => this.handleMatchFilter('red')}>
                    <img src="http://fodboldstatistik.com/red.png" /> {this.state.PlayerData.RedTotal + this.state.PlayerData.RedYellowTotal} røde kort (heraf {this.state.PlayerData.RedYellowTotal} gult/rødt)
                  </div>
                  <div style={{ display: 'block', cursor: 'pointer' }} title="Udskiftninger" onClick={() => this.handleMatchFilter('replacementOut')}>
                    &rarr; {this.state.PlayerData.ReplacementOutTotal} Udskiftninger
                  </div>
                  <div style={{ display: 'block', cursor: 'pointer' }} title="Indskiftninger" onClick={() => this.handleMatchFilter('replacementIn')}>
                    &larr; {this.state.PlayerData.ReplacementInTotal} Indskiftninger
                  </div>
                </div>
                <div style={{ fontSize: '12px', marginBottom: '11px', fontStyle: 'italic' }}>Klik på ikonerne for at filtrere på kampe</div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12 lead">Tidligere klubber</div>
          </div>
          <div className="row">
            <div
              className="col-12 info"
              dangerouslySetInnerHTML={{
                __html: this.state.PlayerData.FormerClubs,
              }}
            ></div>
          </div>
          <div className="row">
            <div className="col-12 lead">Beskrivelse</div>
          </div>
          <div className="row">
            <div
              className="col-12 info"
              dangerouslySetInnerHTML={{
                __html: this.state.PlayerData.Description,
              }}
            ></div>
          </div>
          <div className={NationalMatches === "" ? "hidden" : "row"}>
            <div className="col-12 lead">Landskampe</div>
          </div>
          <div className={NationalMatches === "" ? "hidden" : "row"}>
            <div
              className="col-12 info"
              dangerouslySetInnerHTML={{
                __html: NationalMatches,
              }}
            ></div>
          </div>
          <div className="row admin">
            <div
              className="col-12"
              key={"adminid"}
              dangerouslySetInnerHTML={{
                __html: AdminLink,
              }}
            ></div>
          </div>
        </div>

        <div className="container matches">
          <div className="row">
            <div className="col-12 lead" onClick={() => this.toggleMatches()}>
              <span class="toggleLead">Vis</span>{" "}
              {this.state.PlayerData.FirstName} {this.state.PlayerData.LastName}
              's Kampe
              {this.state.matchFilterText.length > 0 && (
                <span style={{ fontSize: '15px', marginLeft: '10px' }}>({this.state.matchFilterText} - {this.state.FilteredMatchesData.length} kampe)</span>)}
            </div>
          </div>
          <div className="row">
            <div className="col-12 info playersmatches">
              <label>
                <Toggle
                  defaultChecked={this.state.tofuIsReady}
                  icons={false}
                  onChange={this.handleExpandedChange}
                />
                <span>Vis detaljer</span>
              </label>
              {/* <MatchListComponent
                MatchesData={this.state.MatchesData}
                history={this.props.history}
              /> */}

              {this.state.FilteredMatchesData.map((match) => {
                //return (
                if (this.state.ShowExpanded) {
                  return (
                    <MatchComponentDetails
                      MatchData={match}
                      HomeTeam={match.HomeTeam}
                      VisitorTeam={match.VisitorTeam}
                      HomeTeamPlayers={match.HomeTeamPlayers}
                      Events={match.Events}
                      history={this.props.history}
                    />
                  );
                } else {
                  return (
                    <MatchComponentSimple
                      MatchData={match}
                      history={this.props.history}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            &#169; Statistikoplysningerne er indsamlet og indkodet af BK FREMs
            officielle statistikfører: Steen Meisner Bjerre, 23 61 92 72,
            steenbjerre@hotmail.com til hvem henvises, såfremt der er
            ændringer/tilføjelser e.a. til spillerprofilen.
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerComponent;
