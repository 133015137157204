import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import Select from "react-select";
import MatchItem from "./matchitem";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import queryString from "query-string";
import "./matcheslist.css";

class MatchesList extends Component {
  constructor() {
    super();
    this.props = {
      MatchesData: [],
      history: {},
    };
  }

  // componentDidMount() {
  //   this.setState({ MatchesData: null });

  //   let season = this.props.location.pathname.split("/")[2];
  //   console.log("Season: ", season, this.props);
  //   //this.reloadMatches(season);

  //   //this.props.history.push(`/hest`);
  // }

  // reloadMatches(period) {
  //   fetch("http://soccerstats.guideme.today/api/Match/season/" + period)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       this.setState({ MatchesData: data });
  //     })
  //     .catch(console.log);
  // }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  render() {
    if (this.props.MatchesData.length) {
      return (
        <div className="container matches">
          {this.props.MatchesData.map((match) => {
            var HomeLogo = "http://fodboldstatistik.com/teamx.png";
            var VisitorLogo = "http://fodboldstatistik.com/teamx.png";
            if (
              match.HomeTeam !== undefined &&
              match.VisitorTeam !== undefined
            ) {
              if (match.HomeTeam.LogoFileName !== "")
                HomeLogo = "/teams/" + match.HomeTeam.LogoFileName;
              if (match.VisitorTeam.LogoFileName !== "") {
                VisitorLogo = "/teams/" + match.VisitorTeam.LogoFileName;
              }
            }

            return (
              <div
                className="row match"
                onClick={() => this.handleClick(match.Id)}
              >
                {/* <Link to={`/match/${match.Id}`}> */}
                <div className="row">
                  <div className="col-5 icon home">
                    <img src={HomeLogo} />
                    <span className="team">{match.HomeTeam.Name}</span>
                    {/* <div className="col-3 goals"> */}

                    {/* </div> */}
                  </div>
                  <div className="col-2 score home">
                    {match.HomeGoals} - {match.VisitorGoals}
                  </div>
                  <div className="col-5 icon visitor">
                    <span className="team">{match.VisitorTeam.Name}</span>
                    <img src={VisitorLogo} />
                    {/* <div className="col-3 goals"> */}

                    {/* </div> */}
                  </div>
                  {/* </Link> */}
                </div>
                <div class="row matchdate">
                  <Moment style={{ whiteSpace: "pre" }} format={"DD MMM YYYY"}>
                    {match.Date}
                  </Moment>
                </div>
                <div className="row goals">
                  <div className="col-6 home">
                    {match.Events.map((event, index) => {
                      var MinuteOvertime = "";
                      if (event.MinuteOvertime !== 0)
                        MinuteOvertime = "+" + event.MinuteOvertime;

                      if (
                        event.MatchEventType == "Goal" ||
                        event.MatchEventType == "Own goal"
                      ) {
                        var PlayerName = "";
                        var EventMinute = event.Minute;
                        var Comment = "";
                        if (event.Comment.length > 0) Comment = event.Comment;
                        if (Comment.toLowerCase() == "str")
                          Comment = " (" + Comment.toUpperCase() + ")";

                        if (event.TeamId == match.HomeTeam.Id) {
                          PlayerName =
                            event.PlayerFirstName +
                            Comment +
                            " (" +
                            EventMinute +
                            "<div class='overtime'>" +
                            MinuteOvertime +
                            "</div>)";

                          if (event.MatchEventType == "Own goal") {
                            PlayerName += " SELVMÅL";
                          }
                        } else {
                        }

                        return (
                          <div
                            className="goal"
                            dangerouslySetInnerHTML={{
                              __html: PlayerName,
                            }}
                          ></div>
                        );
                      }
                    })}
                  </div>
                  <div className="col-6 visitor">
                    {match.Events.map((event, index) => {
                      if (
                        event.MatchEventType == "Goal" ||
                        event.MatchEventType == "Own goal"
                      ) {
                        var MinuteOvertime = "";
                        if (event.MinuteOvertime !== 0)
                          MinuteOvertime = "+" + event.MinuteOvertime;
                        var PlayerName = "";
                        var EventMinute = event.Minute;
                        var Comment = "";
                        if (event.Comment.length > 0) Comment = event.Comment;
                        if (Comment.toLowerCase() == "str")
                          Comment = " (" + Comment.toUpperCase() + ")";

                        if (event.TeamId == match.HomeTeam.Id) {
                        } else {
                          PlayerName =
                            event.PlayerFirstName +
                            Comment +
                            " (" +
                            EventMinute +
                            "<div class='overtime'>" +
                            MinuteOvertime +
                            "</div>)";

                          if (event.MatchEventType == "Own goal") {
                            PlayerName += " SELVMÅL";
                          }
                        }

                        return (
                          <div
                            className="goal"
                            dangerouslySetInnerHTML={{
                              __html: PlayerName,
                            }}
                          ></div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div className="container matches">No data</div>;
    }
  }
}

export default MatchesList;
