import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import StartComponent from "./components/startcomponent";
import Matches from "./components/matches";
import MatchComponent from "./components/matchcomponent";
import PlayerComponent from "./components/playercomponent";
import PlayersComponent from "./components/playerscomponent";
import PlayersStatsComponent from "./components/playersstatscomponent";
import PlayersStatsGoalsComponent from "./components/playersstatsgoalscomponent";
import PlayersStatsMatchesComponent from "./components/playersstatsmatchescomponent";
import MatchesComponent from "./components/matchescomponent";
import AboutComponent from "./components/aboutcomponent";
import TeamComponent from "./components/teamcomponent";
import TeamsListComponent from "./components/teamslistcomponent";
import NationalTeamPlayersComponent from "./components/nationalteamplayerscomponent";
import PlayerOfTheYearComponent from "./components/playeroftheyearcomponent";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={StartComponent} />
      <Route path="/match" component={MatchComponent} />
      <Route path="/teams" component={TeamsListComponent} />
      <Route path="/player" component={PlayerComponent} />
      <Route path="/season" component={Matches} />
      <Route path="/players" component={PlayersComponent} />
      <Route path="/playersstats" component={PlayersStatsComponent} />
      <Route path="/goalsstats" component={PlayersStatsGoalsComponent} />
      <Route path="/matchesstats" component={PlayersStatsMatchesComponent} />
      <Route path="/matches" component={MatchesComponent} />
      <Route path="/about" component={AboutComponent} />
      <Route path="/team" component={TeamComponent} />
      <Route path="/nationalteamplayers" component={NationalTeamPlayersComponent} />
      <Route path="/playeroftheyear" component={PlayerOfTheYearComponent} />
    </Switch>
  );
}

export default App;
