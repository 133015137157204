import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import Select from "react-select";
import MatchItem from "./matchitem";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import queryString from "query-string";
import "./matches.css";
import MatchListComponent from "./matcheslist";
import MatchComponentDetails from "./matchcomponentdetails";
import MatchComponentSimple from "./matchcomponentsimple";
import Toggle from "react-toggle";

class Matches extends Component {
  constructor() {
    super();
    this.state = {
      MatchesData: [],
      SeasonPlayerStats: [],
      MatchesData: {},
      selectedOption: null,
      ShowExpanded: false,
    };
  }

  handleChange = (selectedOption) => {
    this.props.history.push(`/season/${selectedOption.value}`);
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    if (selectedOption.value !== "choose") {
      //   //this.props.history.push(`/${selectedOption.value}`);
      this.reloadMatches(selectedOption.value);
      this.reloadSeason(selectedOption.value);
    } else {
      this.setState({ MatchesData: null });
    }
  };

  componentDidMount() {
    this.setState({ MatchesData: null });

    let season = this.props.location.pathname.split("/")[2];
    console.log("Season: ", season, this.props);
    this.reloadMatches(season);
    this.reloadSeason(season);

    //this.props.history.push(`/hest`);
  }

  reloadMatches(period) {
    fetch("http://soccerstats.guideme.today/api/match/season/" + period)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        this.calculateSeasonPlayerStats(data);

        this.setState({ MatchesData: data });
      })
      .catch(console.log);;
  }

  reloadSeason(period) {
    fetch("http://soccerstats.guideme.today/api/season/" + period)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ SeasonData: data });
      })
      .catch(console.log);
  }

  loadSeasonPlayers(playersIds, callback) {
    fetch("http://soccerstats.guideme.today/api/player/multiple/" + playersIds)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        callback(data);
      })
      .catch(console.log);
  }

  calculateSeasonPlayerStats(matches) {
    var playersStats = [];

    playersStats.push({
      player: {
        Id: -99,
        FirstName: '',
        LastName: 'Selvmål'
      },
      matches: 0,
      goals: 0
    });

    matches.forEach(match => {
      console.log(match.Date);
      // Loop all players
      match.HomeTeamPlayers.forEach(player => {
        // Check for known player
        var playerMatch = playersStats.filter((existingplayer) => existingplayer.player.Id === player.Id);

        // handle matches
        if (playerMatch.length === 1) {
          // Updates matchcount
          playerMatch[0].matches = playerMatch[0].matches + 1;
        }
        else {
          playersStats.push({
            player: player,
            matches: 1,
            goals: 0
          })
        }
      })

      // handle goals
      match.Events.forEach(event => {
        if (event.MatchEventTypeId === 1) {
          var playerMatch = playersStats.filter((existingplayer) => existingplayer.player.Id === event.PlayerId);
          if (playerMatch.length === 1) {
            playerMatch[0].goals = playerMatch[0].goals + 1;
          }
        }

        if (event.MatchEventTypeId === 5) {
          console.log('SELVMÅL!!!');
          var playerMatch = playersStats.filter((existingplayer) => existingplayer.player.Id === -99);
          if (playerMatch.length === 1) {
            playerMatch[0].goals = playerMatch[0].goals + 1;
          }
        }

        // if (event.MatchEventTypeId === 5) {
        //   var playerMatch = playersStats.filter((existingplayer) => existingplayer.player.Id === event.PlayerId);
        //   if (playerMatch.length === 1) {
        //     playerMatch[0].goals = playerMatch[0].goals + 1;
        //   }
        //   else{
        //     // must be opponent own goal
        //   }
        // }        
      })

    });

    var playerIds = '';
    playersStats.forEach(stat => {
      if (playerIds.length > 0) {
        playerIds = playerIds + "," + stat.player.Id.toString();
      }
      else {
        playerIds = stat.player.Id > 0 ? stat.player.Id.toString() : '';
      }
    });

    console.log(playerIds, 'playerIds');

    var t = this;

    this.loadSeasonPlayers(playerIds, function (playersData) {

      playersData.forEach(player => {

        var playerMatch = playersStats.filter((s) => s.player.Id === player.Id);
        if (playerMatch.length === 1) {
          playerMatch[0].player.MatchesTotal = player.MatchesTotal;
          playerMatch[0].player.GoalsTotal = player.GoalsTotal;
        }

      })


      function sortByMatchCount(a, b) {
        return b.matches - a.matches;
      }

      playersStats.sort(sortByMatchCount);

      t.setState({ SeasonPlayerStats: playersStats });


    })





  }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  handleExpandedChange = (event) => {
    console.log("handleExpandedChange");
    console.log(event.target.checked);

    this.setState({ ShowExpanded: event.target.checked });
  };

  handlePlayerClick = (PlayerId) => {
    console.log(PlayerId);
    if (PlayerId > 0) {
      this.props.history.push(`/player/${PlayerId}`);
    }
    // history.push("/home");
  };

  render() {
    const { selectedOption } = this.state;

    const options = [
      { value: "choose", label: "Vælg sæson" },
      { value: "2024_2025", label: "2024/2025" },
      { value: "2023_2024", label: "2023/2024" },
      { value: "2022_2023", label: "2022/2023" },
      { value: "2021_2022", label: "2021/2022" },
      { value: "2020_2021", label: "2020/2021" },
      { value: "2019_2020", label: "2019/2020" },
      { value: "2018_2019", label: "2018/2019" },
      { value: "2017_2018", label: "2017/2018" },
      { value: "2016_2017", label: "2016/2017" },
      { value: "2015_2016", label: "2015/2016" },
      { value: "2014_2015", label: "2014/2015" },
      { value: "2013_2014", label: "2013/2014" },
      { value: "2012_2013", label: "2012/2013" },
      { value: "2011_2012", label: "2011/2012" },
      { value: "2010_2011", label: "2010/2011" },
      { value: "2009_2010", label: "2009/2010" },
      { value: "2008_2009", label: "2008/2009" },
      { value: "2007_2008", label: "2007/2008" },
      { value: "2006_2007", label: "2006/2007" },
      { value: "2005_2006", label: "2005/2006" },
      { value: "2004_2005", label: "2004/2005" },
      { value: "2003_2004", label: "2003/2004" },
      { value: "2002_2003", label: "2002/2003" },
      { value: "2001_2002", label: "2001/2002" },
      { value: "2000_2001", label: "2000/2001" },
      { value: "1999_2000", label: "1999/2000" },
      { value: "1998_1999", label: "1998/1999" },
      { value: "1997_1998", label: "1997/1998" },
      { value: "1997_F", label: "1997 F" },
      { value: "1996_E", label: "1996 E" },
      { value: "1996_F", label: "1996 F" },
      { value: "1995", label: "1995" },
      { value: "1994", label: "1994" },
      { value: "1993_E", label: "1993 E" },
      { value: "1993_F", label: "1993 F" },
      { value: "1992_E", label: "1992 E" },
      { value: "1991_1992", label: "1991/1992" },
      { value: "1991_F", label: "1991 F" },
      { value: "1990", label: "1990" },
      { value: "1989", label: "1989" },
      { value: "1988", label: "1988" },
      { value: "1987", label: "1987" },
      { value: "1986", label: "1986" },
      { value: "1985", label: "1985" },
      { value: "1984", label: "1984" },
      { value: "1983", label: "1983" },
      { value: "1982", label: "1982" },
      { value: "1981", label: "1981" },
      { value: "1980", label: "1980" },
      { value: "1979", label: "1979" },
      { value: "1978", label: "1978" },
      { value: "1977", label: "1977" },
      { value: "1976", label: "1976" },
      { value: "1975", label: "1975" },
      { value: "1974", label: "1974" },
      { value: "1973", label: "1973" },
      { value: "1972", label: "1972" },
      { value: "1971", label: "1971" },
      { value: "1970", label: "1970" },
      { value: "1969", label: "1969" },
      { value: "1968", label: "1968" },
      { value: "1967", label: "1967" },
      { value: "1966", label: "1966" },
      { value: "1965", label: "1965" },
      { value: "1964", label: "1964" },
      { value: "1963", label: "1963" },
      { value: "1962", label: "1962" },
      { value: "1961", label: "1961" },
      { value: "1960", label: "1960" },
      { value: "1959", label: "1959" },
      { value: "1958", label: "1958" },
      { value: "1956_1957", label: "1956/1957" },
      { value: "1955_1956", label: "1955/1956" },
      { value: "1954_1955", label: "1954/1955" },
      { value: "1953_1954", label: "1953/1954" },
      { value: "1952_1953", label: "1952/1953" },
      { value: "1951_1952", label: "1951/1952" },
      { value: "1950_1951", label: "1950/1951" },
      { value: "1949_1950", label: "1949/1950" },
      { value: "1948_1949", label: "1948/1949" },
      { value: "1947_1948", label: "1947/1948" },
      { value: "1946_1947", label: "1946/1947" },
      { value: "1945_1946", label: "1945/1946" },
      { value: "1944_1945", label: "1944/1945" },
      { value: "1943_1944", label: "1943/1944" },
      { value: "1942_1943", label: "1942/1943" },
      { value: "1941_1942", label: "1941/1942" },
      { value: "1940_1941", label: "1940/1941" },
      { value: "1939_1940", label: "1939/1940" },
      { value: "1938_1939", label: "1938/1939" },
      { value: "1937_1938", label: "1937/1938" },
      { value: "1936_1937", label: "1936/1937" },
      { value: "1935_1936", label: "1935/1936" },
      { value: "1934_1935", label: "1934/1935" },
      { value: "1933_1934", label: "1933/1934" },
      { value: "1932_1933", label: "1932/1933" },
      { value: "1931_1932", label: "1931/1932" },
      { value: "1930_1931", label: "1930/1931" },
      { value: "1929_1930", label: "1929/1930" },
      { value: "1928_1929", label: "1928/1929" },
      { value: "1927_1928", label: "1927/1928" },
      { value: "1926_1927", label: "1926/1927" },
      { value: "1925_1926", label: "1925/1926" },
      { value: "1924_1925", label: "1924/1925" },
      { value: "1923_1924", label: "1923/1924" },
      { value: "1922_1923", label: "1922/1923" },
      { value: "1921_1922", label: "1921/1922" },
      { value: "1920_1921", label: "1920/1921" },
      { value: "1919_1920", label: "1919/1920" },
      { value: "1918_1919", label: "1918/1919" },
      { value: "1917_1918", label: "1917/1918" },
      { value: "1916_1917", label: "1916/1917" },
      { value: "1915_1916", label: "1915/1916" },
      { value: "1914_1915", label: "1914/1915" },
      { value: "1913_1914", label: "1913/1914" },
      { value: "1912_1913", label: "1912/1913" },
      { value: "1911_1912", label: "1911/1912" },
      { value: "1910_1911", label: "1910/1911" },
      { value: "1909_1910", label: "1909/1910" },
      { value: "1908_1909", label: "1908/1909" },
      { value: "1907_1908", label: "1907/1908" },
      { value: "1906_1907", label: "1906/1907" },
      { value: "1905_1906", label: "1905/1906" },
      { value: "1904_1905", label: "1904/1905" },
      { value: "1903_1904", label: "1903/1904" },
      { value: "1902_1903", label: "1902/1903" },
      { value: "1901_1902", label: "1901/1902" },
      { value: "1900_1901", label: "1900/1901" },
      { value: "1899_1900", label: "1899/1900" },
      { value: "1898_1899", label: "1898/1899" },
      { value: "1897_1898", label: "1897/1898" },
      { value: "1896_1897", label: "1896/1897" },
      { value: "1895_1896", label: "1895/1896" },
      { value: "1894_1895", label: "1894/1895" },
      { value: "1893_1894", label: "1893/1894" },
      { value: "1892_1893", label: "1892/1893" },
      { value: "1891_1892", label: "1891/1892" },
      { value: "1890_1891", label: "1890/1891" },
      { value: "1889_1890", label: "1889/1890" },
    ];
    if (
      this.state.MatchesData !== null &&
      this.state.SeasonData !== undefined
    ) {
      return (
        <div className="container matches">
          <Select options={options} onChange={this.handleChange} />

          <div className="seasonmatchcount">
            Der blev spillet <b>{this.state.MatchesData.length}</b> kampe i
            denne sæson
          </div>
          <div className="row">
            <div className="col-12">
              <label>
                <Toggle
                  defaultChecked={this.state.tofuIsReady}
                  icons={false}
                  onChange={this.handleExpandedChange}
                />
                <span>Vis detaljer</span>
              </label>
              {/* <MatchListComponent
                MatchesData={this.state.MatchesData}
                history={this.props.history}
              /> */}

              {this.state.MatchesData.map((match) => {
                //return (
                if (this.state.ShowExpanded) {
                  return (
                    <MatchComponentDetails
                      MatchData={match}
                      HomeTeam={match.HomeTeam}
                      VisitorTeam={match.VisitorTeam}
                      HomeTeamPlayers={match.HomeTeamPlayers}
                      Events={match.Events}
                      history={this.props.history}
                    />
                  );
                } else {
                  return (
                    <MatchComponentSimple
                      MatchData={match}
                      history={this.props.history}
                    />
                  );
                }
              })}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="seasonplayerstats header">
                <div className="seasonplayerstats-name">Spiller</div>
                <div className="seasonplayerstats-matches">K (S/T)</div>
                <div className="seasonplayerstats-goals">M (S/T)</div>
              </div>
              {this.state.SeasonPlayerStats.map((playerStat) => {
                return (
                  <div className="seasonplayerstats">
                    <div className="seasonplayerstats-name" onClick={() => this.handlePlayerClick(playerStat.player.Id)} >{playerStat.player.FirstName} {playerStat.player.LastName}</div>
                    <div className="seasonplayerstats-matches">{playerStat.matches} / {playerStat.player.MatchesTotal}</div>
                    <div className="seasonplayerstats-matches">{playerStat.goals} / {playerStat.player.GoalsTotal}</div>
                  </div>)
              })}
            </div>
            <div className="col-12 statdescription">
              K: Kampe<br />
              M: Mål<br />
              S: Sæson<br />
              T: Total<br />
            </div>

          </div>

          {/* <MatchListComponent
            MatchesData={this.state.MatchesData}
            history={this.props.history}
          /> */}
          <div className="seasonsummary">
            <div className="resulttabletitle">
              <div>{this.state.SeasonData.ResultSeriesATitle}</div>
            </div>
            <div className="resulttable">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.SeasonData.ResultSeriesAContent,
                }}
              ></div>
            </div>
            <div className="resulttablecomments">
              <div>{this.state.SeasonData.ResultSeriesAComments}</div>
            </div>

            <br />
            <br />
            <div className="playertabletitle">
              <div>{this.state.SeasonData.PlayersSeriesATitle}</div>
            </div>
            <div className="playertable">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.SeasonData.PlayersSeriesAContent,
                }}
              ></div>
            </div>
            <div className="playertablecomments">
              <div>{this.state.SeasonData.PlayersSeriesAComments}</div>
            </div>

            <br />
            <br />
            <div className="resulttabletitle">
              <div>{this.state.SeasonData.ResultSeriesBTitle}</div>
            </div>
            <div className="resulttable">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.SeasonData.ResultSeriesBContent,
                }}
              ></div>
            </div>
            <div className="resulttablecomments">
              <div>{this.state.SeasonData.ResultSeriesBComments}</div>
            </div>

            <br />
            <br />
            <div className="playertabletitle">
              <div>{this.state.SeasonData.PlayersSeriesBTitle}</div>
            </div>
            <div className="playertable">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.SeasonData.PlayersSeriesBContent,
                }}
              ></div>
            </div>
            <div className="playertablecomments">
              <div>{this.state.SeasonData.PlayersSeriesBComments}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container matches">
          <Select options={options} onChange={this.handleChange} />
          Vælg sæson
        </div>
      );
    }
  }
}

export default Matches;
