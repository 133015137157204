import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import $ from "jquery";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import { Match } from "../types/match.ts";
import "./matchcomponentdetails.css";

class MatchComponentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Padding: false,
    };
  }

  Greeting(props) {
    const Minuate = props.toggleSpacer;
    if (Minuate >= 800) {
      return <div>SPACER</div>;
    }
    return "";
  }

  componentDidMount() {
    var $this = $("div.row.events div.events");
    console.log($this);
    $this.scrollLeft(385);
  }

  handleClick = (MatchId) => {
    console.log(MatchId);
    this.props.history.push(`/match/${MatchId}`);
    // history.push("/home");
  };

  handlePlayerClick = (PlayerId) => {
    console.log(PlayerId);
    this.props.history.push(`/player/${PlayerId}`);
    // history.push("/home");
  };

  render() {
    // const values = Object.entries(MatchData).map((match, index) => {
    //   return match;
    // });

    //console.log(MatchData.HomeTeam);

    var MatchData = this.props.MatchData;
    var match = this.props.MatchData;
    var HomeTeam = this.props.HomeTeam;
    var VisitorTeam = this.props.VisitorTeam;
    var HomeTeamPlayers = this.props.HomeTeamPlayers;
    var Events = this.props.Events;

    var HomeLogo = "http://fodboldstatistik.com/teamx.png";
    var VisitorLogo = "http://fodboldstatistik.com/teamx.png";
    if (HomeTeam.LogoFileName !== "")
      HomeLogo = "/teams/" + HomeTeam.LogoFileName;
    if (VisitorTeam.LogoFileName !== "") {
      VisitorLogo = "/teams/" + VisitorTeam.LogoFileName;
    }

    var Startes = HomeTeamPlayers.filter(function (player) {
      return player.RowInMatch !== "99";
    });
    var Substitutes = HomeTeamPlayers.filter(function (player) {
      return player.RowInMatch === "99";
    });

    var AdminLink =
      '<a href="http://fodboldstatistik.com/admin/match.html?matchid=' +
      MatchData.Id +
      '" style="color:transparent;">EDIT MATCH</a>';

    return (
      <div
        className="containerdetails match matchdetails"
      // onClick={() => this.handleClick(match.Id)}
      >
        <div className="row match">
          <div className="col-3 icon home">
            <img src={HomeLogo} />
          </div>
          <div className="col-2 goals home">{MatchData.HomeGoals}</div>
          <div className="col-2 seperator">-</div>
          <div className="col-2 goals visitor">{MatchData.VisitorGoals}</div>
          <div className="col-3 icon visitor">
            <img src={VisitorLogo} />
          </div>
        </div>
        <div className="row teams">
          <div className="col-6 home">{HomeTeam.Name}</div>
          <div className="col-6 visitor">{VisitorTeam.Name}</div>
        </div>
        <div className="row info">
          <div className="12">
            <Moment
              style={{ whiteSpace: "pre" }}
              format={"DD[.] MMM YYYY [kl.] HH.mm"}
            >
              {MatchData.Date}
            </Moment>
            <br />
            {MatchData.Series}
          </div>
        </div>
        <div className="row players">
          <div className="col-3 lead">Spillere</div>
          <div className="col-9 playerlist">
            {Startes.map((player) => {
              var RowInMatch = player.RowInMatch;
              var TeamLeader = player.TeamLeader === 1 ? " (Anfører)" : "";

              return (
                <Link to={`/player/${player.Id}`}>
                  <div className={`player`} data-playerid={player.Id}>
                    <div className="number"></div>
                    <div className="name">
                      {player.FirstName} {player.LastName} {TeamLeader}{" "}
                      <div className="position">
                        ({player.PositionInMatch}/{RowInMatch})
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
            <div className="substitutes">Indskiftere:</div>
            {Substitutes.map((player) => {
              var RowInMatch = player.RowInMatch;
              var TeamLeader = player.TeamLeader === 1 ? " (Anfører)" : "";

              return (
                <Link to={`/player/${player.Id}`}>
                  <div className={`player`} data-playerid={player.Id}>
                    <div className="number"></div>
                    <div className="name">
                      {player.FirstName} {player.LastName} {TeamLeader}{" "}
                      <div className="position">
                        ({player.PositionInMatch}/{RowInMatch})
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="row events">
          <div className="col-12 lead">Hændelser</div>
          <div className="col-12 events">
            <table>
              {Events.map((event, index) => {
                var EventIconLeft = "&nbsp;";
                var EventIconRight = "&nbsp;";
                var PlayerLeft = "&nbsp;";
                var PlayerRight = "&nbsp;";
                var Minute = "";
                var MinuteOvertime = "";
                var EventIcon = "";
                var Comment = "";

                if (event.MatchEventType == "Goal") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football.svg">';
                }
                if (event.MatchEventType == "Own goal") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football_red.svg">';
                }
                if (event.MatchEventTypeId == 6) {
                  // Panalty shootout Goal
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football_grey.svg">';
                }
                if (event.MatchEventTypeId == 7) {
                  // Panalty shootout Missed
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football_missed.svg">';
                }
                if (event.MatchEventTypeId == 8) {
                  // Panalty shootout Missed
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football_missed.svg">';
                  event.PlayerFirstName =
                    event.PlayerFirstName + " fik ikke skudt";
                }
                if (event.MatchEventTypeId == 11) {
                  // Panalty Missed
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/football_missed.svg">';
                }
                if (event.MatchEventType == "Replacement") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/transfer.svg">';

                  var PlayerIn = event.PlayerFirstName;
                  var PlayerInSplit = PlayerIn.split(" ");
                  console.log(PlayerInSplit);
                  PlayerIn =
                    PlayerInSplit[0].substring(0, 1) +
                    ". " +
                    PlayerInSplit[PlayerInSplit.length - 1];
                  var PlayerOut = event.ReplacementPlayerFirstName;
                  var PlayerOutSplit = PlayerOut.split(" ");
                  PlayerOut =
                    PlayerOutSplit[0].substring(0, 1) +
                    ". " +
                    PlayerOutSplit[PlayerOutSplit.length - 1];

                  event.PlayerFirstName =
                    "<span style='color:green;' onclick=\"location.href='" + "/#/player/" + event.PlayerId + "'\">" +
                    PlayerIn +
                    "</span> / <span style='color:red;;' onclick=\"location.href='" + "/#/player/" + event.ReplacementPlayerId + "'\">" +
                    PlayerOut +
                    "</span>";
                }
                if (event.MatchEventType == "Yellow") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/yellow.png">';
                }
                if (event.MatchEventType == "Red") {
                  EventIcon = '<img src="http://fodboldstatistik.com/red.png">';
                }
                if (event.MatchEventType == "RedYellow") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/redyellow.png">';
                }
                if (event.MatchEventType == "NyAnforer") {
                  EventIcon =
                    '<img src="http://fodboldstatistik.com/green.png">';
                  event.PlayerFirstName = event.PlayerFirstName + " (anf)";
                }
                var FloatTeam = "home";
                if (event.Comment.length > 0) {
                  Comment = event.Comment;
                  Comment = Comment.toUpperCase();
                  Comment = " (" + Comment + ") ";
                }
                if (event.TeamId == HomeTeam.Id) {
                  // Left
                  EventIconLeft = EventIcon;
                  if (event.PlayerId > 0) {
                    if (event.MatchEventType === "Replacement") {
                      PlayerLeft = "<span style='color:black;cursor:pointer' >" + event.PlayerFirstName + "</span>" + Comment;
                    }
                    else {
                      PlayerLeft = "<span style='color:black;cursor:pointer' onclick=\"location.href='" + "/#/player/" + event.PlayerId + "'\">" + event.PlayerFirstName + "</span>" + Comment;
                    }

                  }
                  else {
                    PlayerLeft = event.PlayerFirstName + Comment;
                  }
                } else {
                  // Right
                  EventIconRight = EventIcon;
                  // PlayerRight = "<span style='color:black;cursor:pointer' onclick=\"location.href='" + "/#/player/" + event.PlayerId + "'\">" + event.PlayerFirstName + "</span>" + Comment;
                  if (event.PlayerId > 0) {
                    if (event.MatchEventType === "Replacement") {
                      PlayerRight = "<span style='color:black;cursor:pointer'>" + event.PlayerFirstName + "</span>" + Comment;
                    }
                    else {
                      PlayerRight = "<span style='color:black;cursor:pointer' onclick=\"location.href='" + "/#/player/" + event.PlayerId + "'\">" + event.PlayerFirstName + "</span>" + Comment;
                    }
                  }
                  else {
                    PlayerRight = event.PlayerFirstName + Comment;
                  }
                  FloatTeam = "visitor";
                }
                Minute = event.Minute + "'";
                if (event.Minute >= 800) {
                  Minute = "-";
                }

                if (event.MinuteOvertime !== 0)
                  MinuteOvertime = "+" + event.MinuteOvertime;

                let button;

                if (event.Minute >= 800) {
                  button = "penaltyshootout";
                } else {
                  button = "";
                }

                // if (event.MatchEventType == "Goal")
                // {
                return (
                  <tr className="event">
                    <td
                      // onClick={() => this.handlePlayerClick(event.PlayerId)}
                      className="player left LEFTY"
                      key={index + "c"}
                      dangerouslySetInnerHTML={{
                        __html: PlayerLeft,
                      }}
                    ></td>
                    <td
                      className="icon"
                      key={index + "b"}
                      dangerouslySetInnerHTML={{
                        __html: EventIconLeft,
                      }}
                    ></td>
                    <td className="minutewrapper" key={index + "d"}>
                      <div className="minute" key={index + "e"}>
                        {Minute}
                        <div className="minuteovertime" key={index + "f"}>
                          {MinuteOvertime}
                        </div>
                      </div>
                    </td>
                    <td
                      className="icon"
                      key={index + "h"}
                      dangerouslySetInnerHTML={{
                        __html: EventIconRight,
                      }}
                    ></td>
                    <td
                      // onClick={() => this.handlePlayerClick(event.PlayerId)}
                      className="player right RIGHTY"
                      key={index + "i"}
                      dangerouslySetInnerHTML={{
                        __html: PlayerRight,
                      }}
                    ></td>
                  </tr>
                  // <div className={`event ${button}`} data-id={event.Id}>
                  //   <div className="left" key={index + "a"}>
                  //     <div
                  //       className="icon"
                  //       key={index + "b"}
                  //       dangerouslySetInnerHTML={{
                  //         __html: EventIconLeft
                  //       }}
                  //     ></div>
                  //     <div
                  //       className="player"
                  //       key={index + "c"}
                  //       dangerouslySetInnerHTML={{
                  //         __html: PlayerLeft
                  //       }}
                  //     ></div>
                  //   </div>
                  //   <div className="minutewrapper" key={index + "d"}>
                  //     <div className="minute" key={index + "e"}>
                  //       {Minute}
                  //       <div className="minuteovertime" key={index + "f"}>
                  //         {MinuteOvertime}
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className="right" key={index + "g"}>
                  //     <div
                  //       className="icon"
                  //       key={index + "h"}
                  //       dangerouslySetInnerHTML={{
                  //         __html: EventIconRight
                  //       }}
                  //     ></div>
                  //     <div
                  //       className="player"
                  //       key={index + "i"}
                  //       dangerouslySetInnerHTML={{
                  //         __html: PlayerRight
                  //       }}
                  //     ></div>
                  //   </div>
                  // </div>
                );
                // }
              })}
            </table>
          </div>
          {/* <div>
            <img
              className="faderight"
              src="http://localhost:3000/eventsfade.png"
            />
          </div> */}
        </div>

        <div className="row events">
          <div className="col-3 lead">Spillerinfo</div>
          <div
            className="col-9 playernotes"
            dangerouslySetInnerHTML={{
              __html: MatchData.PlayerNotes,
            }}
          ></div>
        </div>
        <div className="row events">
          <div className="col-3 lead">Kampinfo</div>
          <div
            className="col-9 matchnotes"
            dangerouslySetInnerHTML={{
              __html: MatchData.MatchNotes,
            }}
          ></div>
        </div>
        <div className="row events">
          <div className="col-3 lead">Tilskuere</div>
          <div className="col-9 spectators">{MatchData.Spectators}</div>
        </div>
        <div className="row events">
          <div className="col-3 lead">Dommer</div>
          <div className="col-9 spectators">{MatchData.Referee}</div>
        </div>
        <div className="row admin">
          <div className="col-3 lead"></div>
          <div
            className="col-9"
            key={"adminid"}
            dangerouslySetInnerHTML={{
              __html: AdminLink,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default MatchComponentDetails;
