import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./playersstatscomponent.css";

class PlayersStatsComponent extends Component {
  constructor() {
    super();
    this.state = {
      PlayersData: [],
      FilteredByGoalsPlayerData: [],
      FilteredByMatchesPlayerData: [],
    };

    this.handleKeyUp = this.keyUpHandler.bind(this, "PlayerNameFilter");
  }

  componentDidMount() {
    this.setState({ PlayersData: null });

    this.loadPlayers();

    //this.props.history.push(`/hest`);
  }

  keyUpHandler(refName, e) {
    console.log(e.target.value);
    if (e.target.value.length > 1) this.filterPlayerData(e.target.value);
    else {
      this.setState({ FilteredPlayerData: this.state.PlayersData });
    }
    // prints either LoginInput or PwdInput
  }

  filterPlayerData(filterValue) {
    var FilteredPlayerData = this.state.PlayersData.filter(function (player) {
      return player.GoalsTotal > 99;
    });

    this.setState({ FilteredPlayerData: FilteredPlayerData });

    var test = FilteredPlayerData;
  }

  SortGoalsTotal(a, b) {
    if (a.GoalsTotal < b.GoalsTotal) {
      return 1;
    }
    if (a.GoalsTotal > b.GoalsTotal) {
      return -1;
    }
    return 0;
  }

  SortMatchesTotal(a, b) {
    if (a.MatchesTotal < b.MatchesTotal) {
      return 1;
    }
    if (a.MatchesTotal > b.MatchesTotal) {
      return -1;
    }
    return 0;
  }

  loadPlayers() {
    fetch("http://soccerstats.guideme.today/api/player/top")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ PlayersData: data });
        var FilteredByGoalsPlayerData = data.filter(function (player) {
          return player.GoalsTotal > 49;
        });
        FilteredByGoalsPlayerData.sort(this.SortGoalsTotal);

        var FilteredByMatchesPlayerData = data.filter(function (player) {
          return player.MatchesTotal > 99;
        });
        FilteredByMatchesPlayerData.sort(this.SortMatchesTotal);

        this.setState({ FilteredByGoalsPlayerData: FilteredByGoalsPlayerData });
        this.setState({
          FilteredByMatchesPlayerData: FilteredByMatchesPlayerData,
        });
      })
      .catch(console.log);
  }

  handleClick = (PlayerId) => {
    console.log(PlayerId);
    this.props.history.push(`/player/${PlayerId}`);
    // history.push("/home");
  };

  render() {
    if (this.state.FilteredByGoalsPlayerData !== null) {
      var currentLetter = "_";

      return (
        <div className="container playersstats">
          <div className="otherstatswrapper">
            <div className="otherstats" onClick={() => {
              this.props.history.push(`/goalsstats`);
            }}>
              Mere end 50 mål
              <div>Liste over alle spillere i FREM med 50 eller flere mål</div>
            </div>
            <div className="otherstats" onClick={() => {
              this.props.history.push(`/matchesstats`);
            }}>
              Mere end 100 kampe
              <div>Liste over alle spillere i FREM med 100 eller flere kampe</div>
            </div>
            <div className="otherstats" onClick={() => {
              this.props.history.push(`/playeroftheyear`);
            }}>
              Årets spiller
              <div>Liste over alle årets spillere igennem tiden </div>
            </div>
            <div className="otherstats" onClick={() => {
              this.props.history.push(`/nationalteamplayers`);
            }}>
              Landsholdsspillere
              <div>Liste over alle FREM's landsholdsspillere igennem tiden</div>
            </div>
          </div>

          <br />
          <br />
        </div>
      );
    } else {
      return <div className="container players">Indlæser spillere...</div>;
    }

    // return (
    //   <div class="container player">
    //     <div className="row">Her lister vi alle spillere fra A - Å</div>
    //   </div>
    // );
  }
}

export default PlayersStatsComponent;
