import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./nationalteamplayerscomponent.css";
import moment from "moment";

const players = [
  {
    player: 462,
    firstName: '*Sophus',
    lastName: 'Nielsen',
    born: '1888-03-15',
    died: '1963-08-06',
    first: '1908-10-19',
    last: '1919-10-12',
    ntMatches: 20,
    ntGoals: 16,
    matches: 137,
    goals: 111,
    comments: 'Kaldt Krølben. Hans 10 mål i hans anden landskamp var verdensrekord i mange år. Hans antal af scorede mål er saandsynligvis højere, da en del mål ikke er registreret i hans aktive periode.'
  },
  {
    player: 632,
    firstName: '*Marius',
    lastName: 'Andersen',
    born: '1885-04-25',
    died: '1972-03-20',
    first: '1908-10-19',
    last: '1908-10-19',
    ntMatches: 1,
    ntGoals: 0,
    matches: 102,
    goals: 21,
    comments: ''
  },
  {
    player: 612,
    firstName: 'Sophus',
    lastName: 'Hansen',
    born: '1889-11-16',
    died: '1962-02-19',
    first: '1911-10-21',
    last: '1920-08-28',
    ntMatches: 31,
    ntGoals: 0,
    matches: 160,
    goals: 0,
    comments: 'Første der nåede 25 kampe. Bror til Hans.'
  },
  {
    player: 580,
    firstName: '*Ødbert',
    lastName: 'Bjarnholt',
    born: '1885-12-24',
    died: '1946-01-03',
    first: '1911-10-21',
    last: '1914-05-17',
    ntMatches: 2,
    ntGoals: 0,
    matches: 111,
    goals: 23,
    comments: 'født Christensen) Skiftede navn i 1907.'
  },
  {
    player: 641,
    firstName: '*Axel',
    lastName: 'Petersen',
    born: '1887-12-10',
    died: '1968-12-20',
    first: '1911-10-21',
    last: '1912-06-30',
    ntMatches: 2,
    ntGoals: 0,
    matches: 113,
    goals: 31,
    comments: ''
  },
  {
    player: 626,
    firstName: 'Hjalmar',
    lastName: 'Christoffersen',
    born: '1889-12-01',
    died: '1966-12-28',
    first: '1912-06-30',
    last: '1912-06-30',
    ntMatches: 1,
    ntGoals: 0,
    matches: 64,
    goals: 42,
    comments: ''
  },
  {
    player: 627,
    firstName: 'Johannes',
    lastName: 'Hansen',
    born: '1886-11-14',
    died: '1946-05-25',
    first: '1913-05-25',
    last: '1917-10-14',
    ntMatches: 3,
    ntGoals: 0,
    matches: 67,
    goals: 3,
    comments: ''
  },
  {
    player: 630,
    firstName: 'Richard',
    lastName: 'Rasmussen',
    born: '1892-03-20',
    died: '1966-09-07',
    first: '1913-10-05',
    last: '1913-10-05',
    ntMatches: 1,
    ntGoals: 0,
    matches: 26,
    goals: 1,
    comments: ''
  },
  {
    player: 629,
    firstName: 'Otto',
    lastName: 'Larsen',
    born: '1893-04-05',
    died: '1969-07-23',
    first: '1918-06-02',
    last: '1923-09-30',
    ntMatches: 10,
    ntGoals: 0,
    matches: 129,
    goals: 20,
    comments: ''
  },
  {
    player: 585,
    firstName: 'Bernhard',
    lastName: 'Andersen',
    born: '1892-02-05',
    died: '1958-09-09',
    first: '1918-10-20',
    last: '1920-08-29',
    ntMatches: 1,
    ntGoals: 1,
    matches: 84,
    goals: 32,
    comments: 'Kaldt "Bette"'
  },
  {
    player: 628,
    firstName: 'Hans',
    lastName: 'Hansen',
    born: '1891-02-05',
    died: '1976-01-20',
    first: '1920-06-13',
    last: '1920-10-10',
    ntMatches: 2,
    ntGoals: 0,
    matches: 127,
    goals: 20,
    comments: 'Kaldt "Reserve" Hans. Bror til Sophus.'
  },
  {
    player: 633,
    firstName: 'Martin',
    lastName: 'Ryding',
    born: '1895-02-06',
    died: '1965-12-26',
    first: '1925-05-06',
    last: '1925-05-06',
    ntMatches: 1,
    ntGoals: 0,
    matches: 73,
    goals: 8,
    comments: ''
  },
  {
    player: 597,
    firstName: 'Knud',
    lastName: 'Kastrup',
    born: '1898-01-07',
    died: '1954-10-17',
    first: '1923-05-06',
    last: '1927-05-27',
    ntMatches: 1,
    ntGoals: 0,
    matches: 52,
    goals: 29,
    comments: 'Spillede ialt 5 landskampe. (4 for Fremad Amager)'
  },
  {
    player: 591,
    firstName: 'Eiler',
    lastName: 'Holm',
    born: '1904-04-02',
    died: '1965-12-06',
    first: '1923-09-30',
    last: '1928-10-07',
    ntMatches: 3,
    ntGoals: 0,
    matches: 160,
    goals: 11,
    comments: ''
  },
  {
    player: 595,
    firstName: 'Sofus',
    lastName: 'Johansen',
    born: '1900-11-01',
    died: '1974-05-17',
    first: '1925-09-27',
    last: '1931-06-28',
    ntMatches: 8,
    ntGoals: 0,
    matches: 94,
    goals: 20,
    comments: '4 Frem\'ere debuterede i samme kamp.'
  },
  {
    player: 593,
    firstName: 'Robert',
    lastName: 'Jensen',
    born: '1895-06-11',
    died: '1967-07-06',
    first: '1925-09-27',
    last: '1925-09-27',
    ntMatches: 1,
    ntGoals: 0,
    matches: 98,
    goals: 30,
    comments: ''
  },
  {
    player: 560,
    firstName: 'Pauli',
    lastName: 'Jørgensen',
    born: '1905-12-04',
    died: '1993-10-20',
    first: '1925-09-27',
    last: '1939-10-22',
    ntMatches: 47,
    ntGoals: 44,
    matches: 297,
    goals: 288,
    comments: 'Pauli havde landskampsrekorden indtil 1965.'
  },
  {
    player: 599,
    firstName: 'Carl',
    lastName: 'Stoltz',
    born: '1905-07-01',
    died: '1977-07-14',
    first: '1925-09-27',
    last: '1936-10-04',
    ntMatches: 1,
    ntGoals: 0,
    matches: 38,
    goals: 23,
    comments: 'Spillede ialt 8 landskampe/3 mål. (7/3 for B. 93)'
  },
  {
    player: 581,
    firstName: 'Kaj',
    lastName: 'Uldaler',
    born: '1906-11-28',
    died: '1987-04-06',
    first: '1927-05-29',
    last: '1939-06-15',
    ntMatches: 5,
    ntGoals: 1,
    matches: 39,
    goals: 36,
    comments: 'Spillede ialt 38 landskampe/15 mål. (33/14 for B. 93).'
  },
  {
    player: 567,
    firstName: 'Svend',
    lastName: 'Hansen',
    born: '1906-07-05',
    died: '1958-07-25',
    first: '1928-04-22',
    last: '1928-10-07',
    ntMatches: 3,
    ntGoals: 0,
    matches: 191,
    goals: 79,
    comments: 'Bror til Niels. Onkel til John.'
  },
  {
    player: 590,
    firstName: 'Niels',
    lastName: 'Hansen',
    born: '1901-05-11',
    died: '1987-04-01',
    first: '1928-09-16',
    last: '1928-10-07',
    ntMatches: 2,
    ntGoals: 0,
    matches: 150,
    goals: 0,
    comments: 'Bror til Svend. Far til John.'
  },
  {
    player: 594,
    firstName: 'Kay',
    lastName: 'Madsen',
    born: '1902-11-27',
    died: '1979-06-06',
    first: '1928-09-16',
    last: '1928-09-16',
    ntMatches: 1,
    ntGoals: 0,
    matches: 111,
    goals: 1,
    comments: 'Far til Benny Madsen (Pilmark) (2B, 3U og 3 Y landskampe)'
  },
  {
    player: 602,
    firstName: 'Knud',
    lastName: 'Christophersen',
    born: '1905-11-15',
    died: '1975-03-08',
    first: '1929-06-23',
    last: '1934-07-03',
    ntMatches: 21,
    ntGoals: 6,
    matches: 156,
    goals: 16,
    comments: 'Alle hans mål blev scoret på straffespark.'
  },
  {
    player: 566,
    firstName: 'Svend',
    lastName: 'Olsen',
    born: '1908-10-21',
    died: '1998-07-13',
    first: '1930-06-16',
    last: '1932-06-19',
    ntMatches: 4,
    ntGoals: 0,
    matches: 165,
    goals: 4,
    comments: ''
  },
  {
    player: 603,
    firstName: 'Hans',
    lastName: 'Steffensen',
    born: '1907-11-13',
    died: '1984-01-25',
    first: '1933-06-11',
    last: '1935-10-06',
    ntMatches: 9,
    ntGoals: 0,
    matches: 82,
    goals: 0,
    comments: ''
  },
  {
    player: 565,
    firstName: 'Knud',
    lastName: 'Sørensen',
    born: '1909-12-26',
    died: '1979-12-13',
    first: '1935-10-06',
    last: '1938-06-21',
    ntMatches: 2,
    ntGoals: 1,
    matches: 175,
    goals: 39,
    comments: ''
  },
  {
    player: 372,
    firstName: 'Helmuth',
    lastName: 'Søbirk',
    born: '1916-09-08',
    died: '1992-08-19',
    first: '1935-11-03',
    last: '1945-09-30',
    ntMatches: 18,
    ntGoals: 6,
    matches: 146,
    goals: 57,
    comments: 'Spillede ialt 30 landskampe/10 mål  (12/4 for B. 93).'
  },
  {
    player: 561,
    firstName: 'Ove',
    lastName: 'Sørensen',
    born: '1914-02-13',
    died: '1972-09-06',
    first: '1936-06-14',
    last: '1938-06-21',
    ntMatches: 4,
    ntGoals: 0,
    matches: 87,
    goals: 9,
    comments: ''
  },
  {
    player: 354,
    firstName: 'Egon',
    lastName: 'Sørensen',
    born: '1913-02-16',
    died: '1981-10-25',
    first: '1937-09-12',
    last: '1945-09-09',
    ntMatches: 18,
    ntGoals: 0,
    matches: 237,
    goals: 0,
    comments: ''
  },
  {
    player: 463,
    firstName: 'Walther',
    lastName: 'Christensen',
    born: '1918-09-23',
    died: '1965-12-01',
    first: '1939-06-18',
    last: '1942-10-04',
    ntMatches: 10,
    ntGoals: 1,
    matches: 139,
    goals: 23,
    comments: ''
  },
  {
    player: 549,
    firstName: 'Kaj A.',
    lastName: 'Hansen',
    born: '1917-04-22',
    died: '1987-08-12',
    first: '1936-06-30',
    last: '1946-09-01',
    ntMatches: 2,
    ntGoals: 3,
    matches: 5,
    goals: 4,
    comments: 'Kaldet "Lille Kaj" Spillede ialt 27 landskampe/12 mål. (25/9 for B. 93).'
  },
  {
    player: 373,
    firstName: 'Johannes',
    lastName: 'Pløger',
    born: '1922-04-03',
    died: '1991-02-04',
    first: '1940-10-20',
    last: '1948-10-10',
    ntMatches: 21,
    ntGoals: 8,
    matches: 164,
    goals: 100,
    comments: ''
  },
  {
    player: 455,
    firstName: 'Erling',
    lastName: 'Sørensen',
    born: '1920-10-29',
    died: '2002-10-10',
    first: '1941-09-14',
    last: '1948-06-12',
    ntMatches: 5,
    ntGoals: 2,
    matches: 161,
    goals: 82,
    comments: ''
  },
  {
    player: 371,
    firstName: 'Svend',
    lastName: 'Frederiksen',
    born: '1916-05-07',
    died: '1985-06-20',
    first: '1941-10-19',
    last: '1941-11-16',
    ntMatches: 2,
    ntGoals: 0,
    matches: 167,
    goals: 11,
    comments: ''
  },
  {
    player: 367,
    firstName: 'Kaj',
    lastName: 'Christiansen',
    born: '1921-03-19',
    died: '2008-01-14',
    first: '1943-06-20',
    last: '1948-06-15',
    ntMatches: 5,
    ntGoals: 6,
    matches: 103,
    goals: 76,
    comments: ''
  },
  {
    player: 414,
    firstName: 'Leo',
    lastName: 'Nielsen',
    born: '1918-02-19',
    died: '1981-06-13',
    first: '1946-07-17',
    last: '1946-07-17',
    ntMatches: 1,
    ntGoals: 0,
    matches: 133,
    goals: 3,
    comments: 'Debuterede på FREM\'s 60 årige fødselsdag.'
  },
  {
    player: 369,
    firstName: 'Hans',
    lastName: 'Colberg',
    born: '1921-12-14',
    died: '2007-09-25',
    first: '1948-06-15',
    last: '1950-08-27',
    ntMatches: 4,
    ntGoals: 0,
    matches: 151,
    goals: 9,
    comments: 'Var anfører i sin debutkamp.'
  },
  {
    player: 375,
    firstName: 'John',
    lastName: 'Hansen',
    born: '1924-07-24',
    died: '1990-01-12',
    first: '1948-06-15',
    last: '1948-10-10',
    ntMatches: 8,
    ntGoals: 10,
    matches: 115,
    goals: 113,
    comments: 'Scorede på straffespark til 1-0 i det 11. minut i sin debutkamp. Søn af Niels Hansen. Hans onkel var Svend Hansen.'
  },
  {
    player: 402,
    firstName: 'Holger',
    lastName: 'Olsen',
    born: '1921-01-21',
    died: '2009-02-10',
    first: '1949-09-11',
    last: '1949-09-11',
    ntMatches: 1,
    ntGoals: 0,
    matches: 170,
    goals: 0,
    comments: ''
  },
  {
    player: 393,
    firstName: 'Ib',
    lastName: 'Bengtsson',
    born: '1927-05-27',
    died: '2002-12-14',
    first: '1949-09-11',
    last: '1950-11-05',
    ntMatches: 3,
    ntGoals: 0,
    matches: 212,
    goals: 39,
    comments: ''
  },
  {
    player: 411,
    firstName: 'Bendt',
    lastName: 'Jørgensen',
    born: '1924-05-24',
    died: '2000-01-31',
    first: '1950-11-05',
    last: '1951-09-16',
    ntMatches: 2,
    ntGoals: 0,
    matches: 164,
    goals: 8,
    comments: ''
  },
  {
    player: 374,
    firstName: 'Per',
    lastName: 'Henriksen',
    born: '1929-08-15',
    died: '2007-08-07',
    first: '1953-06-27',
    last: '1956-07-01',
    ntMatches: 14,
    ntGoals: 0,
    matches: 168,
    goals: 0,
    comments: ''
  },
  {
    player: 394,
    firstName: 'Henning',
    lastName: 'Jensen',
    born: '1929-12-26',
    died: '2016-01-06',
    first: '1954-06-13',
    last: '1954-06-13',
    ntMatches: 1,
    ntGoals: 0,
    matches: 165,
    goals: 64,
    comments: 'Skiftede sit efternavn til Gronemann (Hans moders pigenavn)'
  },
  {
    player: 400,
    firstName: 'Erik',
    lastName: 'Rasmus Petersen',
    born: '1930-01-08',
    died: '2019-09-06',
    first: '1955-06-19',
    last: '1955-06-19',
    ntMatches: 1,
    ntGoals: 0,
    matches: 95,
    goals: 6,
    comments: ''
  },
  {
    player: 810,
    firstName: 'Knud',
    lastName: 'Petersen',
    born: '1934-11-21',
    died: '',
    first: '1957-05-25',
    last: '1957-05-25',
    ntMatches: 1,
    ntGoals: 0,
    matches: 75,
    goals: 10,
    comments: ''
  },
  {
    player: 366,
    firstName: 'Kaj',
    lastName: 'Hansen',
    born: '1940-08-16',
    died: '2009-07-02',
    first: '1963-05-19',
    last: '1967-09-24',
    ntMatches: 7,
    ntGoals: 0,
    matches: 193,
    goals: 20,
    comments: 'Spillede alle sine 7 kampe på udebane'
  },
  {
    player: 365,
    firstName: 'Birger',
    lastName: 'Larsen',
    born: '1942-03-27',
    died: '2024-01-28',
    first: '1963-06-29',
    last: '1966-06-26',
    ntMatches: 12,
    ntGoals: 0,
    matches: 199,
    goals: 1,
    comments: ''
  },
  {
    player: 399,
    firstName: 'Leif',
    lastName: 'Nielsen',
    born: '1942-05-28',
    died: '',
    first: '1964-06-17',
    last: '1967-10-22',
    ntMatches: 28,
    ntGoals: 0,
    matches: 164,
    goals: 0,
    comments: ''
  },
  {
    player: 360,
    firstName: 'Flemming',
    lastName: 'Mortensen',
    born: '1944-07-07',
    died: '',
    first: '1968-09-25',
    last: '1977-10-19',
    ntMatches: 13,
    ntGoals: 0,
    matches: 193,
    goals: 40,
    comments: 'Spillede ialt 16 landskampe (3 for Slagelse).'
  },
  {
    player: 390,
    firstName: 'Leif',
    lastName: 'Prinztlau',
    born: '1948-12-16',
    died: '',
    first: '1968-09-25',
    last: '1972-09-08',
    ntMatches: 7,
    ntGoals: 1,
    matches: 258,
    goals: 34,
    comments: ''
  },
  {
    player: 378,
    firstName: 'Flemming',
    lastName: 'Ahlberg',
    born: '1946-12-23',
    died: '',
    first: '1972-04-18',
    last: '1978-02-08',
    ntMatches: 33,
    ntGoals: 0,
    matches: 332,
    goals: 13,
    comments: ''
  },
  {
    player: 361,
    firstName: 'Valdemar',
    lastName: 'Hansen',
    born: '1946-05-19',
    died: '',
    first: '1972-08-16',
    last: '1973-05-02',
    ntMatches: 2,
    ntGoals: 0,
    matches: 72,
    goals: 0,
    comments: 'Blev indskiftet i begge sine landskampe.'
  },
  {
    player: 420,
    firstName: 'John',
    lastName: 'Frandsen',
    born: '1948-09-19',
    died: '',
    first: '1973-06-21',
    last: '1973-06-21',
    ntMatches: 1,
    ntGoals: 0,
    matches: 11,
    goals: 2,
    comments: ''
  },
  {
    player: 206,
    firstName: 'Lars',
    lastName: 'Larsen',
    born: '1951-12-08',
    died: '',
    first: '1974-06-03',
    last: '1979-05-09',
    ntMatches: 22,
    ntGoals: 0,
    matches: 514,
    goals: 94,
    comments: ''
  },
  {
    player: 204,
    firstName: 'Per',
    lastName: 'Wind',
    born: '1955-08-15',
    died: '',
    first: '1977-01-30',
    last: '1977-06-22',
    ntMatches: 2,
    ntGoals: 0,
    matches: 590,
    goals: 0,
    comments: ''
  },
  {
    player: 528,
    firstName: 'Jan J.',
    lastName: 'Sørensen',
    born: '1955-05-14',
    died: '2024-02-16',
    first: '1977-01-30',
    last: '1980-06-04',
    ntMatches: 5,
    ntGoals: 3,
    matches: 11,
    goals: 6,
    comments: 'Spillede ialt 11 landskampe (6/0 mål for FC Brügge)'
  },
  {
    player: 343,
    firstName: 'Kim',
    lastName: 'Vilfort',
    born: '1962-11-15',
    died: '',
    first: '1983-10-05',
    last: '1996-06-16',
    ntMatches: 4,
    ntGoals: 0,
    matches: 151,
    goals: 93,
    comments: 'Spillede ialt 77 landskampe (73/14 mål for Brøndby).'
  },
  {
    player: 261,
    firstName: 'Ole',
    lastName: 'Rasmussen',
    born: '1960-09-03',
    died: '',
    first: '1984-04-22',
    last: '1985-01-27,',
    ntMatches: 2,
    ntGoals: 0,
    matches: 218,
    goals: 7,
    comments: ''
  },
  {
    player: 263,
    firstName: 'Søren',
    lastName: 'Lyng',
    born: '1966-07-08',
    died: '',
    first: '1990-09-05',
    last: '1991-06-15',
    ntMatches: 2,
    ntGoals: 0,
    matches: 145,
    goals: 63,
    comments: ''
  },
  {
    player: 205,
    firstName: 'Michael "Mio"',
    lastName: 'Nielsen',
    born: '1965-02-11',
    died: '',
    first: '1993-01-30',
    last: '1993-01-30',
    ntMatches: 1,
    ntGoals: 0,
    matches: 100,
    goals: 12,
    comments: ''
  }
]

class NationalTeamPlayersComponent extends Component {
  constructor() {
    super();
    this.state = {
      players: players,
    };

  }

  componentDidMount() {
    // this.setState({ PlayersData: null });

    // this.loadPlayers();

    //this.props.history.push(`/hest`);
  }

  render() {

    var notes = [];
    var commentNode = 1;

    players.forEach((player, index) => {
      if (player.comments.length > 0) {
        notes.push({
          commentId: commentNode,
          comment: player.comments
        });
        player.commentNodeNumber = commentNode;
        commentNode++;
      }
    })




    return (
      <div className="container nationalteamplayers">
        <div className="statslead">Landsholdsspillere</div>

        <div className="abrevation-help mobile-only" style={{ padding: '3px', border: '1px solid #bbbbbb', textAlign: 'center', fontSize: '8pt', marginTop: '8px', marginBottom: '8px' }}>F: Født - D: Død - D: Debut - S: Sidste kamp</div>

        <div className="player">
          <div className="right">
            <div style={{ width: '50%', textAlign: 'center' }}>Landsholdet</div>
            <div style={{ width: '50%', textAlign: 'center' }}>FREM</div>
          </div>
          <div className="left" style={{ width: 'auto', display: 'flex', flexDirection: 'column' }}>
            <div className="name"></div>
          </div>
        </div>

        <div className="player">
          <div className="right">
            <div style={{ width: '25%', textAlign: 'center' }}>Kampe</div>
            <div style={{ width: '25%', textAlign: 'center' }}>Mål</div>
            <div style={{ width: '25%', textAlign: 'center' }}>Kampe</div>
            <div style={{ width: '25%', textAlign: 'center' }}>Mål</div>
          </div>
          <div className="left">
            <div className="name"></div>
            <div className="startend desktop-only">Første/Sidste kamp</div>
            <div className="dates desktop-only">Født/Død</div>
          </div>
        </div>

        {players !== null && (
          players.map((player) => {
            return (
              <div className="player">
                <div className="right">
                  <div style={{ width: '25%', textAlign: 'center' }}>{player.ntMatches}</div>
                  <div style={{ width: '25%', textAlign: 'center' }}>{player.ntGoals}</div>
                  <div style={{ width: '25%', textAlign: 'center' }}>{player.matches}</div>
                  <div style={{ width: '25%', textAlign: 'center' }}>{player.goals}</div>
                </div>
                <div className="left">
                  <div className="name"><Link to={`/player/${player.player}`}>{player.firstName} {player.lastName} <span className='comment'>{player.commentNodeNumber}</span></Link></div>
                  <div className="startend"><div className="mobile-only">Periode:</div>{new moment(player.first).format('DD.MM.YYYY')} - {player.last.length > 0 ? new moment(player.last).format('DD.MM.YYYY') : ''}</div>
                  <div className="dates"><div className="mobile-only">F:</div> {new moment(player.born).format('DD.MM.YYYY')} -  <div className="mobile-only">D:</div> {player.died !== null && player.died.length > 0 ? new moment(player.died).format('DD.MM.YYYY') : ''}</div>
                </div>
              </div>
            )
          })
        )}
        <hr />
        <div className="comments">
          {notes.map((note) => {
            return <div className="comment" ><span className="commentnote">{note.commentId}</span>{note.comment}</div>
          })}
        </div>
        <div className="comments">
          * Hans antal af scorede mål er sandsynligvis højere, da en del mål ikke er registreret i hans aktive periode.
        </div>

        <div className="container">
          <div className="copyright">
            &#169; Statistikoplysningerne er indsamlet og indkodet af BK FREMs
            officielle statistikfører: Steen Meisner Bjerre, 23 61 92 72,
            steenbjerre@hotmail.com til hvem henvises, såfremt der er
            ændringer/tilføjelser e.a. til spillerprofilen.
          </div>
        </div>
      </div>
    );
  }
}

export default NationalTeamPlayersComponent;
