import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./playerscomponent.css";

class PlayersComponent extends Component {
  constructor() {
    super();
    this.state = {
      PlayersData: [],
      FilteredPlayerData: []
    };

    this.handleKeyUp = this.keyUpHandler.bind(this, "PlayerNameFilter");
  }

  componentDidMount() {
    this.setState({ PlayersData: null });

    this.loadPlayers();

    //this.props.history.push(`/hest`);
  }

  keyUpHandler(refName, e) {
    console.log(e.target.value);
    if (e.target.value.length > 1) this.filterPlayerData(e.target.value);
    else {
      this.setState({ FilteredPlayerData: this.state.PlayersData });
    }
    // prints either LoginInput or PwdInput
  }

  filterPlayerData(filterValue) {
    var FilteredPlayerData = this.state.PlayersData.filter(function(player) {
      return (
        player.FirstName.toLowerCase().indexOf(filterValue.toLowerCase()) >
          -1 ||
        player.LastName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
      );
    });

    this.setState({ FilteredPlayerData: FilteredPlayerData });

    var test = FilteredPlayerData;
  }

  loadPlayers() {
    fetch("http://soccerstats.guideme.today/api/player")
      .then(res => res.json())
      .then(data => {
        console.log(data);
        this.setState({ PlayersData: data });
        this.setState({ FilteredPlayerData: data });
      })
      .catch(console.log);
  }

  handleClick = PlayerId => {
    console.log(PlayerId);
    this.props.history.push(`/player/${PlayerId}`);
    // history.push("/home");
  };

  render() {
    if (this.state.FilteredPlayerData !== null) {
      var currentLetter = "_";

      return (
        <div className="container players">
          <div className="playerscount">
            Der blev fundet <b>{this.state.FilteredPlayerData.length}</b>{" "}
            spillere
          </div>
          <input
            type="text"
            onKeyUp={this.handleKeyUp}
            ref="PlayerNameFilter"
          />

          {this.state.FilteredPlayerData.map(player => {
            var letterSpacer = "";
            var tempCurrentLetter = player.FirstName.substring(
              0,
              1
            ).toUpperCase();

            if (tempCurrentLetter != currentLetter) {
              currentLetter = tempCurrentLetter;
              letterSpacer = <div className="letter">{currentLetter}</div>;
            }

            return (
              <div>
                {letterSpacer}
                <div
                  className="player"
                  onClick={() => this.handleClick(player.Id)}
                >
                  {player.FirstName} {player.LastName}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div className="container players">Indlæser spillere...</div>;
    }

    // return (
    //   <div class="container player">
    //     <div className="row">Her lister vi alle spillere fra A - Å</div>
    //   </div>
    // );
  }
}

export default PlayersComponent;
