import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./aboutcomponent.css";

class AboutComponent extends Component {
  state = {};
  render() {
    return (
      <div class="container player">
        <div className="row">
          <div class="col-lg-12">
            <br />
            Oplysninger om FREMs tidligere 1. holdsspillere er indsamlet og
            indkodet af FREMs officielle statistikfører:
            <br />
            <b>Steen Meisner Bjerre</b>
            <br />
            <br />
            Er der spørgsmål, rettelser eller tilføjelser til spillerprofiler
            mv. kan de sendes til
            <br />
            steenbjerre@hotmail.com eller på tlf. 23 61 92 72
            <br />
            <br />
            Indholdet på denne side må ikke gengives uden aftale med Steen
            Meisner Bjerre
            <br />
            <br />
            <div className="row match">
              <div className="col-6">
                <img src="/sb.jpg" className="sbprofile" />
              </div>

              <div className="col-6">
                <img src="/logostjerne.png" className="logoprofile" />
              </div>
            </div>
            <i>Steen Meisner Bjerre</i>
            <br />
            <br />
            <i>Statistik materiale: Steen Meisner Bjerre © copyright.</i>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutComponent;
