import React from "react";
import { render } from "react-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Menu from "./components/menu";
import * as serviceWorker from "./serviceWorker";
import { Route, NavLink, BrowserRouter, HashRouter } from "react-router-dom";

render(
  <HashRouter>
    <Menu />
    <App />
  </HashRouter>,
  document.getElementById("root")
);

// ReactDOM.render(, document.getElementById("menu"));
// ReactDOM.render(<App />, );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
