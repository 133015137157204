import React, { Component } from "react";

class MatchItem extends Component {
  state = {};
  render() {
    return (
      <div class="row">
        <div class="col-2">
          <div class="matchdate">
            04. AUG
            <br />
            2018
          </div>
        </div>
        <div class="col-1">
          <div class="row">
            <div class="col-sm">1</div>
          </div>
          <div class="row">
            <div class="col-sm">0</div>
          </div>
        </div>
        <div class="col-1">
          <div class="row">
            <div class="col-sm">A</div>
          </div>
          <div class="row">
            <div class="col-sm">B</div>
          </div>
        </div>
        <div class="col-7">
          <div class="row">
            <div class="col-sm">Hold</div>
          </div>
          <div class="row">
            <div class="col-sm">Hold</div>
          </div>
        </div>
        <div class="col-1">See</div>
      </div>
    );
  }
}

export default MatchItem;
