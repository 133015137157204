import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./startcomponent.css";
import LatestMatchComponent from "./latestmatchcomponent";

class StartComponent extends Component {
  state = {};
  render() {
    return (
      <div>
        <div class="container start">
          <div class="jumbotron">
            <p class="lead">Statistik for</p>
            <h2 class="display-5">Boldklubben FREM</h2>
            <p>
              Her finder du alt omkring kampe, spillere, modstanderhold mv. for
              alle klubben kampe igennem tiderne.
              <br />
              Siden administreres og vedligeholdes af Steen Meisner Bjerre.
            </p>

            {/* <hr class="my-4" />
            <p>
              På dette websted finder du en masse statistik over kampe og
              spillere
            </p>
            <a class="btn btn-light btn-lg" href="/#/matches" role="button">
              Kom igang
            </a> */}
          </div>
        </div>
        <div class="container teaser">
          <div class="row">
            <div class="col-lg-4">
              <img src="shout.svg" />
              <h2>Seneste kamp</h2>
              <LatestMatchComponent history={this.props.history} />
            </div>
            <div class="col-lg-4">
              <a href="/#/matches" className="cursor">
                <img src="field.svg" />
              </a>
              <h2>Kampe</h2>
              <p>
                Her finder du alle kampe fordelt på sæsoner. For hver kamp ses
                spillere, scoringer og kampinfo mv.
              </p>
              <p>
                <a class="btn btn-light" href="/#/matches" role="button">
                  Alle kampe »
                </a>
              </p>
            </div>
            <div class="col-lg-4">
              <a href="/#/players" className="cursor">
                <img src="player.svg" />
              </a>
              <h2>Spillere</h2>
              <p>
                Her finder du alle oplysninger om spillerne såsom navn,
                fødselsdag, antal kampe. og billede
              </p>
              <p>
                <a class="btn btn-light" href="/#/players" role="button">
                  Alle spillere »
                </a>
              </p>
            </div>
          </div>
          <div class="container teaser">&nbsp;</div>
        </div>
      </div>
    );
  }
}

export default StartComponent;
