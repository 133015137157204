import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./matchescomponent.css";

class MatchesComponent extends Component {
  state = {};
  render() {
    return (
      <div class="container player">
        <div className="row">
          <div className="col-12">
            Her lister vi alle sæsoner fra 1889 - 2025
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Link to={`/season/2024_2025`}>2024/2025 sæsonen</Link>
            <br />
            <Link to={`/season/2023_2024`}>2023/2024 sæsonen</Link>
            <br />
            <Link to={`/season/2022_2023`}>2022/2023 sæsonen</Link>
            <br />
            <Link to={`/season/2021_2022`}>2021/2022 sæsonen</Link>
            <br />
            <Link to={`/season/2020_2021`}>2020/2021 sæsonen</Link>
            <br />
            <Link to={`/season/2019_2020`}>2019/2020 sæsonen</Link>
            <br />
            <Link to={`/season/2018_2019`}>2018/2019 sæsonen</Link>
            <br />
            <Link to={`/season/2017_2018`}>2017/2018 sæsonen</Link>
            <br />
            <Link to={`/season/2016_2017`}>2016/2017 sæsonen</Link>
            <br />
            <Link to={`/season/2015_2016`}>2015/2016 sæsonen</Link>
            <br />
            <Link to={`/season/2014_2015`}>2014/2015 sæsonen</Link>
            <br />
            <Link to={`/season/2013_2014`}>2013/2014 sæsonen</Link>
            <br />
            <Link to={`/season/2012_2013`}>2012/2013 sæsonen</Link>
            <br />
            <Link to={`/season/2011_2012`}>2011/2012 sæsonen</Link>
            <br />
            <Link to={`/season/2010_2011`}>2010/2011 sæsonen</Link>
            <br />
            <Link to={`/season/2009_2010`}>2009/2010 sæsonen</Link>
            <br />
            <Link to={`/season/2008_2009`}>2008/2009 sæsonen</Link>
            <br />
            <Link to={`/season/2007_2008`}>2007/2008 sæsonen</Link>
            <br />
            <Link to={`/season/2006_2007`}>2006/2007 sæsonen</Link>
            <br />
            <Link to={`/season/2005_2006`}>2005/2006 sæsonen</Link>
            <br />
            <Link to={`/season/2004_2005`}>2004/2005 sæsonen</Link>
            <br />
            <Link to={`/season/2003_2004`}>2003/2004 sæsonen</Link>
            <br />
            <Link to={`/season/2002_2003`}>2002/2003 sæsonen</Link>
            <br />
            <Link to={`/season/2001_2002`}>2001/2002 sæsonen</Link>
            <br />
            <Link to={`/season/2000_2001`}>2000/2001 sæsonen</Link>
            <br />
            <Link to={`/season/1999_2000`}>1999/2000 sæsonen</Link>
            <br />
            <Link to={`/season/1998_1999`}>1998/1999 sæsonen</Link>
            <br />
            <Link to={`/season/1997_1998`}>1997/1998 sæsonen</Link>
            <br />
            <Link to={`/season/1997_F`}>1997_F sæsonen</Link>
            <br />
            <Link to={`/season/1996_E`}>1996_E sæsonen</Link>
            <br />
            <Link to={`/season/1996_F`}>1996_F sæsonen</Link>
            <br />
            <Link to={`/season/1995`}>1995 sæsonen</Link>
            <br />
            <Link to={`/season/1994`}>1994 sæsonen</Link>
            <br />
            <Link to={`/season/1993_E`}>1993_E sæsonen</Link>
            <br />
            <Link to={`/season/1993_F`}>1993_F sæsonen</Link>
            <br />
            <Link to={`/season/1992_E`}>1992_E sæsonen</Link>
            <br />
            <Link to={`/season/1991_1992`}>1991_1992 sæsonen</Link>
            <br />
            <Link to={`/season/1991_F`}>1991_F sæsonen</Link>
            <br />
            <Link to={`/season/1990`}>1990 sæsonen</Link>
            <br />
            <Link to={`/season/1989`}>1989 sæsonen</Link>
            <br />
            <Link to={`/season/1988`}>1988 sæsonen</Link>
            <br />
            <Link to={`/season/1987`}>1987 sæsonen</Link>
            <br />
            <Link to={`/season/1986`}>1986 sæsonen</Link>
            <br />
            <Link to={`/season/1985`}>1985 sæsonen</Link>
            <br />
            <Link to={`/season/1984`}>1984 sæsonen</Link>
            <br />
            <Link to={`/season/1983`}>1983 sæsonen</Link>
            <br />
            <Link to={`/season/1982`}>1982 sæsonen</Link>
            <br />
            <Link to={`/season/1981`}>1981 sæsonen</Link>
            <br />
            <Link to={`/season/1980`}>1980 sæsonen</Link>
            <br />
            <Link to={`/season/1979`}>1979 sæsonen</Link>
            <br />
            <Link to={`/season/1978`}>1978 sæsonen</Link>
            <br />
            <Link to={`/season/1977`}>1977 sæsonen</Link>
            <br />
            <Link to={`/season/1976`}>1976 sæsonen</Link>
            <br />
            <Link to={`/season/1975`}>1975 sæsonen</Link>
            <br />
            <Link to={`/season/1974`}>1974 sæsonen</Link>
            <br />
            <Link to={`/season/1973`}>1973 sæsonen</Link>
            <br />
            <Link to={`/season/1972`}>1972 sæsonen</Link>
            <br />
            <Link to={`/season/1971`}>1971 sæsonen</Link>
            <br />
            <Link to={`/season/1970`}>1970 sæsonen</Link>
            <br />
            <Link to={`/season/1969`}>1969 sæsonen</Link>
            <br />
            <Link to={`/season/1968`}>1968 sæsonen</Link>
            <br />
            <Link to={`/season/1967`}>1967 sæsonen</Link>
            <br />
            <Link to={`/season/1966`}>1966 sæsonen</Link>
            <br />
            <Link to={`/season/1965`}>1965 sæsonen</Link>
            <br />
            <Link to={`/season/1964`}>1964 sæsonen</Link>
            <br />
            <Link to={`/season/1963`}>1963 sæsonen</Link>
            <br />
            <Link to={`/season/1962`}>1962 sæsonen</Link>
            <br />
            <Link to={`/season/1961`}>1961 sæsonen</Link>
            <br />
            <Link to={`/season/1960`}>1960 sæsonen</Link>
            <br />
            <Link to={`/season/1959`}>1959 sæsonen</Link>
            <br />
            <Link to={`/season/1958`}>1958 sæsonen</Link>
            <br />
            <Link to={`/season/1956_1957`}>1956/1957 sæsonen</Link>
            <br />
            <Link to={`/season/1955_1956`}>1955/1956 sæsonen</Link>
            <br />
            <Link to={`/season/1954_1955`}>1954/1955 sæsonen</Link>
            <br />
            <Link to={`/season/1953_1954`}>1953/1954 sæsonen</Link>
            <br />
            <Link to={`/season/1952_1953`}>1952/1953 sæsonen</Link>
            <br />
            <Link to={`/season/1951_1952`}>1951/1952 sæsonen</Link>
            <br />
            <Link to={`/season/1950_1951`}>1950/1951 sæsonen</Link>
            <br />
            <Link to={`/season/1949_1950`}>1949/1950 sæsonen</Link>
            <br />
            <Link to={`/season/1948_1949`}>1948/1949 sæsonen</Link>
            <br />
            <Link to={`/season/1947_1948`}>1947/1948 sæsonen</Link>
            <br />
            <Link to={`/season/1946_1947`}>1946/1947 sæsonen</Link>
            <br />
            <Link to={`/season/1945_1946`}>1945/1946 sæsonen</Link>
            <br />
            <Link to={`/season/1944_1945`}>1944/1945 sæsonen</Link>
            <br />
            <Link to={`/season/1943_1944`}>1943/1944 sæsonen</Link>
            <br />
            <Link to={`/season/1942_1943`}>1942/1943 sæsonen</Link>
            <br />
            <Link to={`/season/1941_1942`}>1941/1942 sæsonen</Link>
            <br />
            <Link to={`/season/1940_1941`}>1940/1941 sæsonen</Link>
            <br />
            <Link to={`/season/1939_1940`}>1939/1940 sæsonen</Link>
            <br />
            <Link to={`/season/1938_1939`}>1938/1939 sæsonen</Link>
            <br />
            <Link to={`/season/1937_1938`}>1937/1938 sæsonen</Link>
            <br />
            <Link to={`/season/1936_1937`}>1936/1937 sæsonen</Link>
            <br />
            <Link to={`/season/1935_1936`}>1935/1936 sæsonen</Link>
            <br />
            <Link to={`/season/1934_1935`}>1934/1935 sæsonen</Link>
            <br />
            <Link to={`/season/1933_1934`}>1933/1934 sæsonen</Link>
            <br />
            <Link to={`/season/1932_1933`}>1932/1933 sæsonen</Link>
            <br />
            <Link to={`/season/1931_1932`}>1931/1932 sæsonen</Link>
            <br />
            <Link to={`/season/1930_1931`}>1930/1931 sæsonen</Link>
            <br />
            <Link to={`/season/1929_1930`}>1929/1930 sæsonen</Link>
            <br />
            <Link to={`/season/1928_1929`}>1928/1929 sæsonen</Link>
            <br />
            <Link to={`/season/1927_1928`}>1927/1928 sæsonen</Link>
            <br />
            <Link to={`/season/1926_1927`}>1926/1927 sæsonen</Link>
            <br />
            <Link to={`/season/1925_1926`}>1925/1926 sæsonen</Link>
            <br />
            <Link to={`/season/1924_1925`}>1924/1925 sæsonen</Link>
            <br />
            <Link to={`/season/1923_1924`}>1923/1924 sæsonen</Link>
            <br />
            <Link to={`/season/1922_1923`}>1922/1923 sæsonen</Link>
            <br />
            <Link to={`/season/1921_1922`}>1921/1922 sæsonen</Link>
            <br />
            <Link to={`/season/1920_1921`}>1920/1921 sæsonen</Link>
            <br />
            <Link to={`/season/1919_1920`}>1919/1920 sæsonen</Link>
            <br />
            <Link to={`/season/1918_1919`}>1918/1919 sæsonen</Link>
            <br />
            <Link to={`/season/1917_1918`}>1917/1918 sæsonen</Link>
            <br />
            <Link to={`/season/1916_1917`}>1916/1917 sæsonen</Link>
            <br />
            <Link to={`/season/1915_1916`}>1915/1916 sæsonen</Link>
            <br />
            <Link to={`/season/1914_1915`}>1914/1915 sæsonen</Link>
            <br />
            <Link to={`/season/1913_1914`}>1913/1914 sæsonen</Link>
            <br />
            <Link to={`/season/1912_1913`}>1912/1913 sæsonen</Link>
            <br />
            <Link to={`/season/1911_1912`}>1911/1912 sæsonen</Link>
            <br />
            <Link to={`/season/1910_1911`}>1910/1911 sæsonen</Link>
            <br />
            <Link to={`/season/1909_1910`}>1909/1910 sæsonen</Link>
            <br />
            <Link to={`/season/1908_1909`}>1908/1909 sæsonen</Link>
            <br />
            <Link to={`/season/1907_1908`}>1907/1908 sæsonen</Link>
            <br />
            <Link to={`/season/1906_1907`}>1906/1907 sæsonen</Link>
            <br />
            <Link to={`/season/1905_1906`}>1905/1906 sæsonen</Link>
            <br />
            <Link to={`/season/1904_1905`}>1904/1905 sæsonen</Link>
            <br />
            <Link to={`/season/1903_1904`}>1903/1904 sæsonen</Link>
            <br />
            <Link to={`/season/1902_1903`}>1902/1903 sæsonen</Link>
            <br />
            <Link to={`/season/1901_1902`}>1901/1902 sæsonen</Link>
            <br />
            <Link to={`/season/1900_1901`}>1900/1901 sæsonen</Link>
            <br />
            <Link to={`/season/1899_1900`}>1899/1900 sæsonen</Link>
            <br />
            <Link to={`/season/1898_1899`}>1898/1899 sæsonen</Link>
            <br />
            <Link to={`/season/1897_1898`}>1897/1898 sæsonen</Link>
            <br />
            <Link to={`/season/1896_1897`}>1896/1897 sæsonen</Link>
            <br />
            <Link to={`/season/1895_1896`}>1895/1896 sæsonen</Link>
            <br />
            <Link to={`/season/1894_1895`}>1894/1895 sæsonen</Link>
            <br />
            <Link to={`/season/1893_1894`}>1893/1894 sæsonen</Link>
            <br />
            <Link to={`/season/1892_1893`}>1892/1893 sæsonen</Link>
            <br />
            <Link to={`/season/1891_1892`}>1891/1892 sæsonen</Link>
            <br />
            <Link to={`/season/1890_1891`}>1890/1891 sæsonen</Link>
            <br />
            <Link to={`/season/1889_1890`}>1889/1890 sæsonen</Link>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default MatchesComponent;
