import React, { Component } from "react";
import { Route, Link, BrowserRouter, HashRouter } from "react-router-dom";
import "./menu.css";

class Menu extends Component {
  state = {};
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="http://fodboldstatistik.com/">
          <img
            className="logo"
            src="http://fodboldstatistik.com/football_foot.svg"
          />
          Fodboldstatistik
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/#/players">
                Spillere
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#/teams">
                Modstandere
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#/matches">
                Kampe
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#/playersstats">
                Statistik
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/#/nationalteamplayers">
                Landsholdsspillere
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/#/about">
                Kontakt
              </a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li>
              <a
                href="/admin"
                id="username"
                data-userid="0"
                className="username"
              ></a>
            </li>
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
                <input className="form-control mr-sm-2" type="search" placeholder="Søg" aria-label="Søg">
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Søg</button>
        </form> */}
        </div>
      </nav>
    );
  }
}

export default Menu;
