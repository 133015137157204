import React, { Component } from "react";
import "./teamcomponent.css";
import Moment from "react-moment";
import "moment-timezone";
import $ from "jquery";
import MatchListComponent from "./matcheslist";
import MatchComponentDetails from "./matchcomponentdetails";
import MatchComponentSimple from "./matchcomponentsimple";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules

class TeamComponent extends Component {
  constructor() {
    super();
    this.state = {
      TeamData: {},
      FirstMatch: {},
      LastMatch: {},
      OpponentTeamData: {},
      MatchesData: [],
      ShowExpanded: false,
    };
  }

  reloadMatches(teamId, opponentTeamId) {
    fetch(
      "http://soccerstats.guideme.today/api/Match/team/" +
      teamId +
      "/" +
      opponentTeamId
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ MatchesData: data });
      })
      .catch(console.log);
  }

  LoadFremStats(teamId, opponentTeamId) {
    fetch(
      "http://soccerstats.guideme.today/api/team/" +
      teamId +
      "/" +
      opponentTeamId
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ OpponentTeamData: data });
      })
      .catch(console.log);
  }

  componentDidMount() {
    let teamId = this.props.location.pathname.split("/")[2];
    let opponentTeamId = this.props.location.pathname.split("/")[3];
    if (opponentTeamId === undefined) opponentTeamId = 100;
    console.log("Id: ", teamId, this.props);
    fetch("http://soccerstats.guideme.today/api/team/" + teamId)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));
        this.setState({
          TeamData: data,
          FirstMatch: data.First,
          LastMatch: data.Last,
        });
        // this.setState({ FirstMatch: data.First });
        // this.setState({ LastMatch: data.Last });
        this.LoadFremStats(teamId, opponentTeamId);
      })
      .catch(console.log);

    this.reloadMatches(teamId, opponentTeamId);
  }

  handleExpandedChange = (event) => {
    console.log("handleExpandedChange");
    console.log(event.target.checked);

    this.setState({ ShowExpanded: event.target.checked });
  };

  // handleClick = MatchId => {
  //   console.log(MatchId);
  //   this.props.history.push(`/match/${MatchId}`);
  //   // history.push("/home");
  // };

  toggleMatches() {
    console.log("Toggle matches");
    $(".teamsmatches").slideToggle();
    if ($(".toggleLead").text() == "Vis") {
      $(".toggleLead").text("Skjul");
    } else {
      $(".toggleLead").text("Vis");
    }
  }

  render() {
    const pic =
      this.state.TeamData.LogoFileName !== ""
        ? "http://fodboldstatistik.com/teams/" +
        this.state.TeamData.LogoFileName
        : "http://fodboldstatistik.com/teams/teamx.png";

    var FirstMatch = this.state.TeamData.First;
    var FirstMatchDate = "";
    var LastMatchDate = "";
    var FirstMatchOpponent = "";
    var LastMatchOpponent = "";
    var FirstMatchOpponentId;
    var LastMatchOpponentId;

    if (this.state.TeamData.First !== undefined) {
      FirstMatchDate = this.state.TeamData.First.Date;
      FirstMatchOpponent =
        this.state.TeamData.First.HomeTeam.Id === this.state.TeamData.Id
          ? this.state.TeamData.First.VisitorTeam.Name
          : this.state.TeamData.First.HomeTeam.Name;
      LastMatchDate = this.state.TeamData.Last.Date;
      LastMatchOpponent =
        this.state.TeamData.Last.HomeTeam.Id === this.state.TeamData.Id
          ? this.state.TeamData.Last.VisitorTeam.Name
          : this.state.TeamData.Last.HomeTeam.Name;
      FirstMatchOpponentId = this.state.TeamData.First.VisitorTeam.Id;
      LastMatchOpponentId = this.state.TeamData.Last.VisitorTeam.Id;
    }

    var OpponemtGoalCount =
      this.state.OpponentTeamData.GoalsTotal; // - this.state.TeamData.GoalsTotal;

    return (
      <div>
        <div class="container player">
          <div className="row">
            <div className="col-12 playername">{this.state.TeamData.Name}</div>
          </div>
          <div className="row bio">
            <div className="col-5 profilepic">
              <img src={pic} />
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-7 lead">Stadion</div>
              </div>
              <div className="row">
                <div className="col-7 info"></div>
              </div>
              <div className="row">
                <div className="col-7 lead">Først kamp</div>
              </div>
              <div className="row">
                <div className="col-7 info">
                  {" "}
                  <Moment
                    style={{ whiteSpace: "pre" }}
                    format={"DD[.] MMM YYYY"}
                  >
                    {FirstMatchDate}
                  </Moment>{" "}
                  mod {FirstMatchOpponent}
                </div>
              </div>
              <div className="row">
                <div className="col-7 lead">Seneste kamp</div>
              </div>
              <div className="row">
                <div className="col-7 info">
                  {" "}
                  <Moment
                    style={{ whiteSpace: "pre" }}
                    format={"DD[.] MMM YYYY"}
                  >
                    {LastMatchDate}
                  </Moment>{" "}
                  mod {LastMatchOpponent}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <b>Frem</b>&nbsp;mod&nbsp;<b>{this.state.TeamData.Name}</b>
          </div>
          <div className="row gamestats">
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">KAMPE</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.OpponentTeamData.MatchesTotal}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">VUND.</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.OpponentTeamData.MatchesWon}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">UAFG.</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.OpponentTeamData.MatchesTie}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">TABTE</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.OpponentTeamData.MatchesLost}
                </div>
              </div>
            </div>
            <div className="col-2">&nbsp;</div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">MÅL</div>
              </div>
              <div className="row">
                <div className="col-12 value center">{OpponemtGoalCount}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <b>{this.state.TeamData.Name}</b>&nbsp;mod&nbsp;<b>Frem</b>
          </div>
          <div className="row gamestats">
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">KAMPE</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.TeamData.MatchesTotal}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">VUND.</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.TeamData.MatchesWon}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">UAFG.</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.TeamData.MatchesTie}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">TABTE</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.TeamData.MatchesLost}
                </div>
              </div>
            </div>
            <div className="col-2">&nbsp;</div>
            <div className="col-2">
              <div className="row">
                <div className="col-12 lead center">MÅL</div>
              </div>
              <div className="row">
                <div className="col-12 value center">
                  {this.state.TeamData.GoalsTotal}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12 lead">
              <h4>Kampe</h4>
            </div>
          </div>
        </div>
        <div className="container matches">
          <div className="row">
            <div className="col-12 info teammatches">
              <label>
                <Toggle
                  defaultChecked={this.state.tofuIsReady}
                  icons={false}
                  onChange={this.handleExpandedChange}
                />
                <span className="toggleDetails">Vis detaljer</span>
              </label>
              {/* <MatchListComponent
                MatchesData={this.state.MatchesData}
                history={this.props.history}
              /> */}

              {this.state.MatchesData.map((match) => {
                //return (
                if (this.state.ShowExpanded) {
                  return (
                    <MatchComponentDetails
                      MatchData={match}
                      HomeTeam={match.HomeTeam}
                      VisitorTeam={match.VisitorTeam}
                      HomeTeamPlayers={match.HomeTeamPlayers}
                      Events={match.Events}
                      history={this.props.history}
                    />
                  );
                } else {
                  return <MatchComponentSimple MatchData={match} history={this.props.history} />;
                }
              })}

              {/* <MatchListComponent
                MatchesData={this.state.MatchesData}
                history={this.props.history}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamComponent;
